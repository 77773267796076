import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { fetchDriverDetails, updateDriverDetails } from '../../../Api'
import { useFormik } from 'formik'
import AppLayout from '../../../Component/AppLayout/AppLayout'
import { assignBucketUrl, globalHandleChange, handleFileUpload } from '../../../Utils/HelperFunctions'
import AutoFillAddress from '../../../Component/AutoFillAddress/AutoFillAddress'
import { updateDriverValidation } from '../../../Utils/Validations'
import { BlueSpinner } from '../../../Utils/Loader'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { API_BASE_URL } from '../../../Utils/BaseUrl'
import ProfilePicDemo from "../../../Assets/image/person.png"
import { toast } from 'react-hot-toast'

const EditDriverProfile = () => {

    const [driverData, setDriverData] = useState({ data: null, loading: false })
    const [loader, setLoader] = useState(false);
    const [loader2, setLoader2] = useState(false);

    const params = useParams()
    const navigate = useNavigate()

    const imgRef = useRef();
    const imgRef2 = useRef();
    const inputRef = useRef();

    const formikDriver = useFormik({
        initialValues: {
            id: "",
            firstName: "",
            lastName: "",
            phone: "",
            address: "",
            company: "asd",
            zipCode: "",
            city: "",
            state: "",
            drivingLicenseNo: "",
            drivingLicensePicture: "",
            drivingLicensePictureBack: "",
            profilePicture: "",
        },
        validationSchema: updateDriverValidation,
        onSubmit: async () => {
            const values = formikDriver.values
            try {
                // console.log("Driver hadle submit...", values)
                const res = await updateDriverDetails(values)
                if (res?.status === 200) {
                    toast.success("Driver profile updated successfully", { id: "001" });
                    fetchDriverDetailsById(params?.id)
                }
            } catch (error) {
                toast.error("Cannot update profile", { id: "002" })
                console.log(error)
            }
        }
    })

    const fetchDriverDetailsById = async (id) => {
        setDriverData({ ...driverData, loading: true })
        try {
            const res = await fetchDriverDetails(id)
            if (res?.status === 200) {
                setDriverData({ data: res?.data, loading: false })
            }
        } catch (error) {
            setDriverData({ ...driverData, loading: false })
            console.log(error)
        }
    }

    const handleChangePhoneInput = (value) => {
        formikDriver.setFieldValue('phone', value)
    }

    const handleRemove = () => {
        inputRef.current.src = ProfilePicDemo;
        formikDriver.setFieldValue("profilePicture", "")
    };

    useEffect(() => {
        fetchDriverDetailsById(params?.id)
    }, [])

    useEffect(() => {
        if (driverData?.data !== "") {
            formikDriver.setFieldValue("id", driverData?.data?.id.toString() || "-")
            formikDriver.setFieldValue("firstName", driverData?.data?.firstName || "-")
            formikDriver.setFieldValue("lastName", driverData?.data?.lastName || "-")
            formikDriver.setFieldValue("email", driverData?.data?.email || "-")
            formikDriver.setFieldValue("phone", driverData?.data?.phone || "-")
            formikDriver.setFieldValue("address", driverData?.data?.address || "-")
            formikDriver.setFieldValue("city", driverData?.data?.city || "-")
            formikDriver.setFieldValue("state", driverData?.data?.state || "-")
            formikDriver.setFieldValue("profilePicture", driverData?.data?.profilePicture || "")
            formikDriver.setFieldValue("drivingLicensePicture", driverData?.data?.drivingLicensePicture || "")
            formikDriver.setFieldValue("drivingLicensePictureBack", driverData?.data?.drivingLicensePictureBack || "")
            formikDriver.setFieldValue("zipCode", driverData?.data?.zipCode || "-")
            formikDriver.setFieldValue("drivingLicenseNo", driverData?.data?.drivingLicenseNo || "-")
        }
    }, [driverData?.data])


    // console.log(formikDriver.values, "driverData")

    return (
        <>
            <AppLayout>
                <main className="p-3 px-5 main">
                    <div className='row d-flex'>
                        <div className='col-lg-6 col-12 d-flex align-items-center'>
                            <div className='col-9'>
                                <h3 className="fw-bold py-4">Edit Information</h3>
                            </div>
                            <div className='col-3 py-4'>
                                <i className="bi bi-arrow-left-circle-fill h3 m-0" onClick={() => navigate("/user-management")} style={{ cursor: "pointer", float: "right" }}></i>
                            </div>
                        </div>
                    </div>
                    {/* <div className="content mt-3  "> */}
                    <div className="row ">
                        {
                            driverData?.loading
                                ?
                                (
                                    <div className='text-center'>
                                        <BlueSpinner />
                                    </div>
                                )
                                :
                                (
                                    <div className="col-lg-6 col-12">
                                        <div>
                                            <div className="d-flex mb-3 justify-content-between align-items-center">
                                                <div style={{ height: "50px", width: "50px", position: "relative" }} className=" ">
                                                    <span className='image-section position-absolute end-0'>
                                                        <i className='bi bi-x-circle' style={{ cursor: "pointer", }} onClick={handleRemove}></i>
                                                    </span>
                                                    <img
                                                        ref={inputRef}
                                                        src={assignBucketUrl(formikDriver?.values?.profilePicture)} alt='#' style={{ width: "100%", height: "100%", objectFit: "cover", }} className='border rounded-3 '
                                                        onError={event => {
                                                            event.target.src = ProfilePicDemo
                                                            event.onerror = null
                                                        }} />
                                                </div>
                                                <label htmlFor='profilePic'>
                                                    <div
                                                        onClick={() => {
                                                            imgRef?.current?.click();
                                                            // formikDriver.setFieldValue("profilePicture", "")

                                                        }}
                                                        style={{ cursor: "pointer" }}
                                                        className=' px-4 py-1 button button-bg button-color fw-semibold'
                                                    >
                                                        Upload
                                                    </div>
                                                </label>
                                                <input type='file' id='profilePic' name='profilePicture' onChange={(e) => {
                                                    handleFileUpload(e, formikDriver, setLoader);
                                                    //  formikDriver.setFieldValue("profilePicture", "") 
                                                }} className='d-none' accept="image/*" />
                                                {Boolean(formikDriver.touched.profilePicture && formikDriver.errors.profilePicture) && <p className='text-danger mt-2'>{formikDriver.errors.profilePicture}</p>}
                                            </div>

                                            <div className="mb-3">
                                                {/* <label htmlFor="firstName" className="form-label fw-semibold p-14 ">First Name</label> */}
                                                <input
                                                    type="text"
                                                    className="form-control "
                                                    id="firstName"
                                                    placeholder="Enter your first name"
                                                    name="firstName"
                                                    value={formikDriver?.values?.firstName}
                                                    onChange={(e) => globalHandleChange(e, formikDriver)}
                                                />
                                                {Boolean(formikDriver.touched.firstName && formikDriver.errors.firstName) && <p className='text-danger mt-2'>{formikDriver.errors.firstName}</p>}
                                            </div>
                                            <div className="mb-3">
                                                {/* <label htmlFor="lastName" className="form-label fw-semibold p-14 ">Last Name</label> */}
                                                <input
                                                    type="text"
                                                    className="form-control "
                                                    id="lastName"
                                                    placeholder="Enter your last name"
                                                    name="lastName"
                                                    value={formikDriver?.values?.lastName}
                                                    onChange={(e) => globalHandleChange(e, formikDriver)}
                                                />
                                                {Boolean(formikDriver.touched.lastName && formikDriver.errors.lastName) && <p className='text-danger mt-2'>{formikDriver.errors.lastName}</p>}
                                            </div>
                                            <div className="mb-3">
                                                {/* <label htmlFor="InputEmail1" className="form-label  fw-semibold">Email</label> */}
                                                <div className="position-relative">
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        id="InputEmail1"
                                                        placeholder="Enter your email "
                                                        name='email'
                                                        value={formikDriver?.values?.email}
                                                        onChange={(e) => globalHandleChange(e, formikDriver)}
                                                    />
                                                    <i className="bi bi-envelope-fill position-absolute icon-p  text-fade"
                                                        style={{
                                                            cursor: 'pointer', right: "1rem", top: "1rem",
                                                            background: "transparent !important", marginLeft: "10px"
                                                        }}
                                                    ></i>
                                                    {Boolean(formikDriver.touched.email && formikDriver.errors.email) && <p className='text-danger mt-2'>{formikDriver.errors.email}</p>}
                                                </div>
                                            </div>

                                            {/* <div className="mb-3">
                                                <label htmlFor="phoneNumber" className="form-label fw-semibold p-14 ">Phone Number</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="phoneNumber"
                                                    placeholder="Enter your phone number"
                                                    name="phone"
                                                    value={formikDriver?.values?.phone}
                                                    onChange={(e) => globalHandleChange(e, formikDriver)}
                                                />
                                                {Boolean(formikDriver.touched.phone && formikDriver.errors.phone) && <p className='text-danger mt-2'>{formikDriver.errors.phone}</p>}
                                            </div> */}

                                            <div className='mb-3'>
                                                {/* <label htmlFor="phoneNumber" className="form-label fw-semibold p-14 ">Phone Number</label> */}
                                                <PhoneInput
                                                    className={Boolean(formikDriver.touched?.phone && formikDriver.errors?.phone) ? "form-control border-danger p-3 cu-45 e-caret-hide" : "cu-45 form-control p-3 e-caret-hide"}
                                                    defaultCountry="US"
                                                    countries={["US"]}
                                                    name="phone"
                                                    placeholder="Enter phone number"
                                                    value={formikDriver.values.phone}
                                                    onChange={handleChangePhoneInput}
                                                    PhoneInputCountrySelectArrow={false}
                                                    international={false}
                                                />
                                                {Boolean(formikDriver.touched.phone && formikDriver.errors.phone) && <p className='text-danger mt-2'>{formikDriver.errors.phone}</p>}
                                            </div>

                                            <div className='mb-3'>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    id='exampleInputEmail1'
                                                    aria-describedby='emailHelp'
                                                    placeholder='Enter your driver license number'
                                                    name='drivingLicenseNo'
                                                    value={formikDriver.values.drivingLicenseNo}
                                                    onChange={(e) => globalHandleChange(e, formikDriver)}
                                                />
                                                {Boolean(formikDriver.touched.drivingLicenseNo && formikDriver.errors.drivingLicenseNo) && <p className='text-danger mt-2'>{formikDriver.errors.drivingLicenseNo}</p>}
                                            </div>



                                            <div className='mb-3'>
                                                <div className='input-group mb-3 d-flex justify-content-between align-items-center text-align-center py-1 form-control pe-1'>
                                                    <div
                                                        className='text-align-center   py-1'
                                                        style={{ textOverflow: "ellipsis", whiteSpace: " nowrap", overflow: "hidden", paddingRight: "60px" }}>
                                                        <h6
                                                            className='text-fade  mt-2 overflow-x-auto overflow-y-auto'
                                                            style={{ textOverflow: "ellipsis", whiteSpace: " nowrap", overflow: "hidden" }}>
                                                            {
                                                                formikDriver?.values?.drivingLicensePicture ? (
                                                                    <a href={assignBucketUrl(formikDriver?.values?.drivingLicensePicture)} target='_blank' rel="noreferrer"  >
                                                                        {formikDriver?.values?.drivingLicensePicture?.split("/")?.at(-1)}
                                                                    </a>
                                                                ) : (
                                                                    "Upload"
                                                                )
                                                            }
                                                        </h6>
                                                    </div>

                                                    <label htmlFor='licenseFile' className='position-absolute end-0'>
                                                        <div
                                                            className='text-fade border p-2 rounded bg  me-1'
                                                            onClick={() => {
                                                                imgRef?.current?.click();
                                                            }}
                                                            style={{ cursor: "pointer" }}>
                                                            File <i className='bi bi-paperclip'></i>
                                                        </div>
                                                    </label>
                                                    <input
                                                        type='file'
                                                        id='licenseFile'
                                                        name='drivingLicensePicture'
                                                        className='form-control d-flex justify-content-end d-none'
                                                        onChange={(e) => {
                                                            handleFileUpload(e, formikDriver, setLoader2);
                                                        }}
                                                        placeholder=''
                                                        accept='image/*'
                                                    />
                                                </div>

                                                {Boolean(formikDriver.touched.drivingLicensePicture && formikDriver.errors.drivingLicensePicture) && (
                                                    <p className='text-danger mt-2'>{formikDriver.errors.drivingLicensePicture}</p>
                                                )}
                                            </div>

                                            <div className='mb-3'>
                                                <div className='input-group mb-3 d-flex justify-content-between align-items-center text-align-center py-1 form-control pe-1'>
                                                    <div
                                                        className='text-align-center   py-1'
                                                        style={{ textOverflow: "ellipsis", whiteSpace: " nowrap", overflow: "hidden", paddingRight: "60px" }}>
                                                        <h6
                                                            className='text-fade  mt-2 overflow-x-auto overflow-y-auto'
                                                            style={{ textOverflow: "ellipsis", whiteSpace: " nowrap", overflow: "hidden" }}>
                                                            {
                                                                formikDriver?.values?.drivingLicensePictureBack ? (
                                                                    <a href={assignBucketUrl(formikDriver?.values?.drivingLicensePictureBack)} target='_blank' rel="noreferrer"  >
                                                                        {formikDriver?.values?.drivingLicensePictureBack?.split("/")?.at(-1)}
                                                                    </a>
                                                                ) : (
                                                                    "Upload"
                                                                )
                                                            }
                                                        </h6>
                                                    </div>

                                                    <label htmlFor='licenseFile2' className='position-absolute end-0'>
                                                        <div
                                                            className='text-fade border p-2 rounded bg  me-1'
                                                            onClick={() => {
                                                                imgRef2?.current?.click();
                                                            }}
                                                            style={{ cursor: "pointer" }}>
                                                            File <i className='bi bi-paperclip'></i>
                                                        </div>
                                                    </label>
                                                    <input
                                                        type='file'
                                                        id='licenseFile2'
                                                        name='drivingLicensePictureBack'
                                                        className='form-control d-flex justify-content-end d-none'
                                                        onChange={(e) => {
                                                            handleFileUpload(e, formikDriver, setLoader2);
                                                        }}
                                                        placeholder=''
                                                        accept='image/*'

                                                    />
                                                </div>
                                            </div>

                                            <AutoFillAddress formik={formikDriver} />

                                        </div>
                                        <div className="col-lg-4 col-12">
                                            <div className=" mt-5">
                                                <button
                                                    className=" px-5 button button-bg button-color fw-semibold"
                                                    onClick={() => { formikDriver.handleSubmit() }}>
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )
                        }
                    </div>
                </main>
            </AppLayout>
        </>
    )
}

export default EditDriverProfile