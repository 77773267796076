import React, { useRef } from 'react'
import ChatList from './ChatList'
import ChatSection from './ChatSection'
import AppLayout from '../../Component/AppLayout/AppLayout'
import { BlueSpinner } from '../../Utils/Loader'
import ChatContext from '../../AppContext/ChatContext'

const Index = () => {

   return (
      <>
         <ChatContext>
            <AppLayout>
               <main className="main-body">
                  <div className="container-fluid pe-xl-5 pe-0 ">
                     <div className="heading ps-5 pe-5   pt-5 me-xxl-5 ">
                        <div className="d-flex justify-content-between align-items-center pb-2">
                           <h3 className="p-20 fw-bold">Support</h3>
                        </div>
                     </div>
                     <div className="row px-5 me-xxl-5 chat ">
                        <ChatList />
                        {/* {
                        console.log(Boolean((chat?.length >= 1) && (!chat?.[0]?.msg)), "boolean result...", chatSectionLoader)
                     } */}
                        {
                           false ?
                              (
                                 <div className='col-xl-8 col-12 bg-white box-shadow chat-wrapper-admin'>
                                    <div className='text-center'>
                                       <BlueSpinner />
                                    </div>
                                 </div>
                              )
                              :
                              // (
                              //    !chat?.[0]?.msg
                              //       ?
                              <ChatSection />
                        }
                     </div>
                  </div>

               </main>
            </AppLayout>
         </ChatContext>
      </>
   )
}

export default Index