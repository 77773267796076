import * as yup from "yup";

export const LoginValidation = yup.object({
  email: yup.string().email("Invalid email").required("Email can't be empty"),
  password: yup
    .string()
    .required("Password can't be empty")
  // .matches(/[0-9]/, "Password requires a number")
  // .matches(/[a-z]/, "Password requires a lowercase letter")
  // .matches(/[A-Z]/, "Password requires an uppercase letter")
  // .matches(/[^\w]/, "Password requires a symbol")
  // .min(8, "Password should be minimum of 8 characters"),
});

export const updateDriverValidation = yup.object({
  firstName: yup.string()
    .required("First name can't be empty")
    .matches(/^[A-Za-z]+$/, "Please enter a valid first name").trim(),
  lastName: yup.string()
    .required("Last name can't be empty")
    .matches(/^[A-Za-z]+$/, "Please enter a valid last name").trim(),
  email: yup.string()
    .email("Invalid email")
    .required("Email can't be empty").trim(),
  phone: yup.string().required("Phone number can't be empty")
    .min(12, "Please enter a valid phone number")
    .max(12, "Please enter a valid phone number"),
  // type: yup.string(),
  address: yup.string().required("Address can't be empty"),
  city: yup.string().required("City can't be empty"),
  state: yup.string().required("State can't be empty"),
  zipCode: yup.string().required("Zip code can't be empty")
    // .matches(/^[A-Za-z0-9 ]*$/, "Please enter a valid Zip Code")
    .min(5, "Please enter a valid zip code")
    .max(6, "Please enter a valid zip code"),
  profilePicture: yup.string(),
});

export const updateDealerValidation = yup.object({
  firstName: yup.string()
    .required("First name can't be empty")
    .matches(/^[A-Za-z]+$/, "Please enter a valid first name").trim(),
  lastName: yup.string()
    .required("Last name can't be empty")
    .matches(/^[A-Za-z]+$/, "Please enter a valid last name").trim(),
  email: yup.string()
    .email("Invalid email")
    .required("Email can't be empty").trim(),
  phone: yup.string().required("Phone number can't be empty")
    .min(12, "Please enter a valid phone number")
    .max(12, "Please enter a valid phone number"),
  // type: yup.string(),
  address: yup.string().required("Address can't be empty"),
  city: yup.string().required("City can't be empty"),
  state: yup.string().required("State can't be empty"),
  company: yup.string().required("Company can't be empty"),
  zipCode: yup.string().required("Zip code can't be empty")
    // .matches(/^[A-Za-z0-9 ]*$/, "Please enter a valid Zip Code")
    .min(5, "Please enter a valid zip code")
    .max(6, "Please enter a valid zip code"),
  profilePicture: yup.string(),
});

export const updateAdminValidation = yup.object({
  firstName: yup.string()
    .required("First name can't be empty")
    .matches(/^[A-Za-z]+$/, "Please enter a valid first name").trim(),
  lastName: yup.string()
    .required("Last name can't be empty")
    .matches(/^[A-Za-z]+$/, "Please enter a valid last name").trim(),
  email: yup.string()
    .email("Invalid email")
    .required("Email can't be empty").trim(),
})