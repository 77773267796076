import React from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const SidebarItem = ({link, children}) => {
  const navigate = useNavigate();
const location = useLocation() 
  return (
    <div className="w-100 mb-2">
      <button className={Boolean(location?.pathname?.includes(link))?"side-button active" : "side-button"} onClick={() => navigate(link)}>
        {children}
      </button>
    </div>
  );
};

export default SidebarItem;
