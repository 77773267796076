import React, { useEffect, useState } from "react";
import AppLayout from "../../Component/AppLayout/AppLayout";
import Highcharts from "highcharts/highstock";
import PieChart from "highcharts-react-official";
import { getStatsOfDealer, getStatsOfDriver } from "../../Api";
import { BlueSpinner } from "../../Utils/Loader";

const Dashboard = () => {
  const [stats, setStats] = useState({
    dealers: "",
    drivers: "",
    loading: true,
  });

  const fetchDealerStats = async () => {
    try {
      const res = await getStatsOfDealer();
      if (res?.status === 200) {
        setStats((v) => ({
          ...v,
          dealers: res?.data.map((el, i) => {
            return {
              name: el?.state,
              y: Number(Number(el?.y).toFixed(2)),
            };
          }),
          loading: false,
        }));
      }
    } catch (error) {
      console.log(error);
      setStats({ loading: false });
    }
  };

  const fetchDriverStats = async () => {
    try {
      const res = await getStatsOfDriver();
      if (res?.status === 200) {
        setStats((v) => ({
          ...v,
          drivers: res?.data.map((el, i) => {
            return {
              name: el?.state,
              y: Number(Number(el?.y).toFixed(2)),
            };
          }),
          loading: false,
        }));
      }
    } catch (error) { 
      setStats({ loading: false });
    }
  };

  const colorsPie = () => {
    let colors = [],
      base = Highcharts.getOptions().colors[0],
      i;

    for (i = 0; i < 10; i += 1) {
      colors.push(
        Highcharts.color(base)
          .brighten((i - 3) / 7)
          .get()
      );
    }

    return colors;
  };

  const dealerOptions = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    title: {
      text: `Dealers`,
      align: "center",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        colors: colorsPie(),
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b><br>{point.percentage:.1f} %",
          distance: -50,
          filter: {
            property: "percentage",
            operator: ">",
            value: 4,
          },
        },
      },
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    series: [
      {
        name: "Dealers (%)",
        data: stats?.dealers || [{ name: "No Data Found", y: 100 }],
      },
    ],
    credits: { enabled: false },
  };

  const driverOptions = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    title: {
      text: `Drivers`,
      align: "center",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        colors: colorsPie(),
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b><br>{point.percentage:.1f} %",
          distance: -50,
          filter: {
            property: "percentage",
            operator: ">",
            value: 4,
          },
        },
      },
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    series: [
      {
        name: "Drivers (%)",
        data: stats?.drivers || [{ name: "No Data Found", y: 100 }],
      },
    ],
    credits: { enabled: false },
  };

  useEffect(() => {
    fetchDealerStats();
    fetchDriverStats();
  }, []);

  return (
    <>
      <AppLayout>
        <main className="p-3 px-5  main">
          <h3 className="fw-bold py-4">Statistics</h3>
          <div className="content mt-3">
            {stats?.loading ? (
              <div className="text-center">
                <BlueSpinner />
              </div>
            ) : (
              <>
                <div className="row">
                  <div className=" col-md-6 ">
                    <div
                      className="card border-0"
                      style={{ 
                        boxShadow: "0px 0px 5px #00000029",
                      }}
                    >
                      <PieChart
                        highcharts={Highcharts}
                        options={dealerOptions}
                      />
                    </div>
                  </div>
                  <div className=" col-md-6">
                    <div
                      className="card border-0"
                      style={{ 
                        boxShadow: "0px 0px 5px #00000029",
                      }}
                    >
                      <PieChart
                        highcharts={Highcharts}
                        options={driverOptions}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </main>
      </AppLayout>
    </>
  );
};

export default Dashboard;
