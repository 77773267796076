import { useFormik } from 'formik';
import React, { useContext, useState } from 'react'
import { loginApi } from '../../Api';
import { LoginValidation } from '../../Utils/Validations';
import toast from "react-hot-toast";
import { useNavigate } from 'react-router-dom';
import LogInSideImg from "../../Assets/image/WhatsApp Image 2022-12-06 at 5.32.38 PM.png"
import Logo from "../../Assets/image/Group 3621.png"
import { ImgLoader2 } from '../../Utils/Loader';
import { UserContext } from '../../AppContext/AppContext';

const Login = () => {

  const [loader, setLoader] = useState(false)
  const { setIsLoggedIn, fetchAdminProfile } = useContext(UserContext)
  const [passwordType, setPasswordType] = useState("password");

  const navigate = useNavigate();

  const loginFormik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    validationSchema: LoginValidation,

    onSubmit: async (values) => {
      setLoader(true)
      // console.log(values);
      const { email, password } = values;
      try {
        const res = await loginApi({ email, password });
        if (res.status === 200 || res.status === 201) {
          toast.success(res?.data?.message, { id: "001" });
          localStorage.setItem("token", res.data.accessToken);
          setIsLoggedIn(true)
          fetchAdminProfile()
          navigate("/dashboard");
          setLoader(false)
        }
      } catch (error) {
        // console.log(error, "><><>")
        setLoader(false)
        toast.error(error?.response?.data?.message || error?.message, { id: "err01" });
      }
    },
  });

  return (
    <>
      <div className="row g-0">
        <div className="col-md-5 px-md-5">
          <div className="container px-md-5">
            <div className="login-logo mt-5">
              <img src={Logo} alt="" />
            </div>
            <div className="" style={{ marginTop: "200px" }} >
              <div>
                <h4 className="fw-bold mb-1">Welcome Back!</h4>
                <p className="text-fade">Log in and continue with your admin portal.</p>
              </div>

              <form onSubmit={loginFormik.handleSubmit}>
                <div className="mb-4">
                  {/* <label htmlFor="exampleInputEmail1" className="form-label p-14 fw-semibold">Email</label> */}
                  <div className="position-relative">
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Enter your email "
                      name='email'
                      value={loginFormik.values.email}
                      onChange={loginFormik.handleChange}
                    />
                    {Boolean(loginFormik.touched.email && loginFormik.errors.email) && <p className='text-danger mt-2'>{loginFormik.errors.email}</p>}
                    <i className="bi bi-envelope-fill position-absolute icon-p text-fade" style={{
                      cursor: 'pointer', right: "1rem", top: "1rem",
                      background: "transparent !important", marginLeft: "10px"
                    }}></i>
                  </div>
                </div>
                <div className="mb-5">
                  {/* <label htmlFor="exampleInputPassword" className="form-label p-14 fw-semibold">Password</label> */}
                  <div className="position-relative">
                    <input
                      type={passwordType}
                      className="form-control"
                      id="exampleInputEmail1"
                      placeholder="Enter your Password "
                      name='password'
                      value={loginFormik.values.password}
                      onChange={loginFormik.handleChange}
                    />
                    {/* <i className="bi bi-eye-slash-fill position-absolute icon-p text-fade"></i> */}
                    <i className={`bi ${passwordType === "password" ? "bi-eye-slash" : "bi-eye"} position-absolute icon-mail text-muted border-start-0`}
                      style={{
                        cursor: 'pointer', right: "1rem", top: "1rem",
                        background: "transparent !important", marginLeft: "10px"
                      }}
                      onClick={() => setPasswordType(passwordType === "password" ? "text" : "password")}></i>
                  </div>
                  {Boolean(loginFormik.touched.password && loginFormik.errors.password) && <p className='text-danger mt-2'>{loginFormik.errors.password}</p>}
                </div>

                <div className="">
                  <button type="submit" className="button text-white w-100  py-3">
                    {!loader ? ("Log in") : (<div className="text-xs "><ImgLoader2 /></div>)}
                  </button>
                </div>
              </form>
            </div>
            <div></div>
          </div>
        </div>
        <div className="col-md-7">
          <div className="login-image">
            <img
              src={LogInSideImg}
              alt=""
            />
          </div>
        </div>
      </div>
    </>

  )
}

export default Login