import React, { useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { isAuth } from '../Utils/HelperFunctions'

const PrivateRoute = ({ children }) => {

   const navigate = useNavigate()
   const auth = isAuth()

   // const isTokenPresent = () => {
   //    // const token = localStorage.getItem("token")
   //    if (!auth) {
   //       // localStorage.clear()
   //       console.log("No token found")
   //       navigate("/")
   //    } else {
   //       console.log("Private Route")
   //    }
   // }

   // useEffect(() => {
   //    isTokenPresent()
   // }, [])

   return auth ? children : <Navigate to='/' />
}

export default PrivateRoute