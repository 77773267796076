import { toast } from "react-hot-toast"
import { fileUploadApi } from "../Api"
import { API_BASE_URL } from "./BaseUrl"

export const getToken = () => localStorage.getItem("token")
export const setToken = (token) => localStorage.setItem("token", token)
export const removeToken = () => localStorage.removeItem("token")
export const isAuth = () => Boolean(getToken())

export const getLastElementId = () => {
   const path = window.localation.pathname.split("/")
   return path[path.length - 1]
}

export const preventBack = () => {
   return window.history.forward()
}

export const logOut = (navigate) => {
   localStorage.clear()
   navigate("/")
}

export const globalHandleChange = (e, formik) => {
   e.preventDefault()
   formik.setFieldValue(e.target.name, e.target.value)
}

export const formatPhoneNumber1 = (data) => {
   // console.log(typeof data, "LLl")
   const phoneNumber = data?.replace(/[^\d]/g, "");
   const phoneNumberLength = phoneNumber?.length;

   if (phoneNumberLength < 4) return phoneNumber;
   if (phoneNumberLength < 7) {
      return `(${phoneNumber?.slice(1, 4)}) ${phoneNumber?.slice(4)}`;
   }

   return (
      <a className="text-decoration-none dark-clr" href={`tel:${data?.phone}`}> {`(${phoneNumber?.slice(1, 4)}) ${phoneNumber?.slice(4, 7)}-${phoneNumber?.slice(7, 11)}`}</a>
   );
};

export const handleFileUpload = async (event, formik, setLoader) => {

   const toastLoader = toast.loading("Uploading...")

   const logoImg = event.target.files[0];
   if (!logoImg) {
      toast.dismiss(toastLoader)
      return
   }

   const imgFile = new FormData();

   imgFile.append("file", logoImg);

   if (logoImg.size > 15000000) {
      toast.error("Please upload file less than 15 mb");
      toast.dismiss(toastLoader)
      return
   }

   try {
      if (logoImg.name.match(/\.(jpg|jpeg|png|jfif|webp|JPG|JPEG|PNG|JFIF|WEBP)$/)) {
         //  console.log(Boolean(logoImg.name.match(/\.(jpg|jpeg|png)$/)), "<><><><><<")
         const res = await fileUploadApi(imgFile);
         if (res.status === 200) {
            formik.setFieldValue(event.target.name, res?.data?.filePath);
            //  setLoader(false)
            toast.dismiss(toastLoader)
            toast.success(res?.data?.message, { id: "001" });
         }
      } else {
         toast.dismiss(toastLoader)

         toast.error("Unsupported Image Format", { id: "err01" });
      }
   } catch (error) {
      toast.dismiss(toastLoader)
   }
};

export const handleFileUploadWithPreview = async (event, formik, setAttaches) => {

   const toastLoader = toast.loading("Uploading...")

   const logoImg = event.target.files[0];
   if (!logoImg) {
      toast.dismiss(toastLoader)
      return
   }

   const imgFile = new FormData();
   setAttaches({ loading: true });

   imgFile.append("file", logoImg);

   if (logoImg.size > 15000000) {
      // toast.error("Please upload file less than 15 mb");
      toast.dismiss(toastLoader)
      toast("File size is more than 10 MB", { icon: "⚠️" });
      setAttaches("");
      return
   }

   try {
      if (logoImg.name.match(/\.(jpg|jpeg|png|jfif|webp|JPG|JPEG|PNG|JFIF|WEBP)$/)) {
         //  console.log(Boolean(logoImg.name.match(/\.(jpg|jpeg|png)$/)), "<><><><><<")
         const res = await fileUploadApi(imgFile);
         if (res.status === 200) {
            formik.setFieldValue(event.target.name, res?.data?.filePath);
            //  setLoader(false)
            setAttaches({ data: res.data.filePath, loading: false });
            toast.dismiss(toastLoader)
            toast.success(res?.data?.message, { id: "001" });
         }
      } else {
         toast.dismiss(toastLoader)
         toast.error("Unsupported Image Format", { id: "err01" });
      }
   } catch (error) {
      toast.dismiss(toastLoader)
   }
};

export const assignBucketUrl = (path) => {
   // console.log(path, "<><><><")
   return encodeURI(path?.startsWith("upload/") ? API_BASE_URL + "/" + path : path)
}