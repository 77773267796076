import React, { useContext, useEffect, useState } from 'react'
import { UserContext, SocketContext } from './AppContext';
import { io } from 'socket.io-client';
import { API_BASE_URL } from '../Utils/BaseUrl';
import { getAllUserChats, getPreviousChat } from '../Api';

const ChatContext = (props) => {

   const { adminDetails, socket, setSocket } = useContext(UserContext)

   // const [socket, setSocket] = useState(io(API_BASE_URL, { transports: ["websocket"], query: { convoId: "1" } }));
   // const [socket, setSocket] = useState("");
   // const [userListSocket, setUserListSocket] = useState(io(API_BASE_URL, { transports: ["websocket"], query: { convoId: "1" } }));
   const [chat, setChat] = useState([]);
   const [convoId, setConvoId] = useState("1");
   const [currentUserDetails, setCurrentUserDetails] = useState("")
   const [attaches2, setAttaches2] = useState({ data: null, loading: false });
   const [chatSectionLoader, setChatSectionLoader] = useState(true);
   const [userChatList, setUserChatList] = useState({ data: [], loading: true });
   const [userChatListFromApi, setUserChaListFromApi] = useState({ data: [], loading: true })

   const fetchPreviousChats = async (p) => {
      try {
         const res = await getPreviousChat(p)
         if (res?.status === 200) {
            setChat(res?.data)
            setChatSectionLoader(false)
         }
      } catch (error) {
         console.log(error)
      }
   }

   const setDefaultChat = (formik) => {

      console.log(userChatListFromApi?.data, "++++++++", formik)

      // setConvoId(Boolean(userChatListFromApi?.data?.[0]?.convoId) && userChatListFromApi?.data?.[0]?.convoId)
      // if (userChatListFromApi?.data?.length > 0) {

      //    formik.setFieldValue("recieverId", `${userChatListFromApi?.data?.[0]?.createrId}`)
      //    formik.setFieldValue("convoId", `${userChatListFromApi?.data?.[0]?.convoId}`)
      //    formik.setFieldValue("senderName", `${adminDetails?.data?.firstName + " " + adminDetails?.data?.lastName}`)
      //    formik.setFieldValue("senderId", `${adminDetails?.data?.id}`)
      //    formik.setFieldValue("recieverName", `${userChatListFromApi?.data?.[0]?.createrName}`)
      //    formik.setFieldValue("profilePicture", `${userChatListFromApi?.data?.[0]?.createrProfile}`)
      //    // console.log(socket_, "asasasasas")
      //    setSocket(io(API_BASE_URL, { transports: ["websocket"], query: { convoId: Boolean(userChatListFromApi?.data?.[0]?.convoId) && userChatListFromApi?.data?.[0]?.convoId } }))
      //    fetchPreviousChats({ convoId: userChatListFromApi?.data?.[0]?.convoId, page: 1, limit: 50 })
      //    setChatSectionLoader(false)
      //    if (chat?.length > 1) {
      //       console.log(chat?.length, "KKK")
      //    }
      // }  
      // else {
      //    // setSocket(io(API_BASE_URL, { transports: ["websocket"], query: { convoId: "1" } }))
      //    // socket = io(API_BASE_URL, { transports: ["websocket"], query: { convoId: "1" } })
      // }
   }

   const selectChatUser = (param) => {
      console.log(param, "parammmmmmm")
      if (param?.convoId !== "") {
         setConvoId(Boolean(userChatListFromApi?.data?.[0]?.convoId) && userChatListFromApi?.data?.[0]?.convoId)
         setCurrentUserDetails(param)
         setSocket(io(API_BASE_URL, { transports: ["websocket"], query: { convoId: Boolean(param?.convoId) && param?.convoId } }))
         fetchPreviousChats({ convoId: param?.convoId, page: 1, limit: 50 })

         // setChat((v) => ({ ...v, data: null, loading: true }))
         setChat([])
         setChatSectionLoader(true)
      }
   }

   const fetchAllChatUsers = async () => {
      try {
         const res = await getAllUserChats()
         if (res?.status === 200) {
            setUserChaListFromApi((v) => ({ ...v, data: res?.data, loading: false }))
         }
      } catch (error) {
         console.log(error)
      }
   }

   // useEffect(() => {
   //    if (userChatList?.data.length > 1) {
   //       socket.on("previous message", (message) => {
   //          // console.log(message, "previous message")
   //          // setChat({ data: message, loading: false });
   //          setChat(message);
   //          setChatSectionLoader(false)
   //       });
   //    }
   // }, [socket]);

   useEffect(() => {
      fetchAllChatUsers()
   }, [])

   return (
      <SocketContext.Provider
         value={{
            socket,
            setSocket,
            setUserChatList,
            userChatList,
            chat,
            setChat,
            currentUserDetails,
            setCurrentUserDetails,
            chatSectionLoader,
            setChatSectionLoader,
            setDefaultChat,
            selectChatUser,
            setConvoId,
            userChatListFromApi,
            fetchPreviousChats
         }}
      >
         {props.children}
      </SocketContext.Provider>
   )
}

export default ChatContext