import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAllGigs } from "../../Api";
import Moment from "react-moment";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { useFormik } from "formik";
import { BlueSpinner } from "../../Utils/Loader";
import SearchGigs from "./SearchGigs";
import AppLayout from "../../Component/AppLayout/AppLayout";
import Pagination from "../../Component/Pagination/Pagination";

const AllGigs = () => {

  const [dashState, setDashState] = useState({
    driverProfile: { loading: true, data: null, user: null },
    allGigsData: { loading: true, totalGigs: 0, data: "", totalPageCount: "" },
    allGigsCount: { loading: true, data: null, user: null },
    searchFilter: {
      from: "", to: "",
      pickUpDate: ""
      // moment(new Date()).format()
      , pickUpTime: "", payout: "", page: 1, limit: 10, filter: ""
    },
  });

  const formik = useFormik({

    initialValues: {
      from: "",
      to: "",
      pickUpDate:
        // new Date() ||
        "",
      pickUpTime: "",
      payout: "",
      page: 1,
      limit: 10,
      filter: ""

    },

    validationSchema: "",

    onSubmit: async (values) => {
      setDashState((v) => ({ ...v, allGigsData: { loading: true, totalGigs: 0, data: "", totalPageCount: "" } }));

      try {

        const payload = { ...values };

        // console.log(payload, "<:<:<:<:<:<:<:")

        payload.pickUpDate = values.pickUpDate ? moment(values.pickUpDate).format() : "";
        // payload.pickUptime = moment(values.pickUptime).format();
        const res = await getAllGigs(payload);

        if (res.status === 200) {
          setDashState((v) => ({
            ...v,
            driverProfile: { loading: false },
            allGigsData: {
              loading: false,
              totalGigs: res?.data?.totalGigs,
              data: res?.data?.AllGigs,
              totalPageCount: res.data.pages
            },
            searchFilter: {
              ...dashState?.searchFilter,
              filter: payload?.filter
            },
          }));
        }
      } catch (err) {
        setDashState((v) => ({ ...v, driverProfile: { loading: false, totalGigs: 0, data: "", totalPageCount: "" } }));
      }
    },
  });

  const handleChange = (name, value) => {
    setDashState((v) => ({ ...v, searchFilter: { ...dashState?.searchFilter, [name]: value } }));
    // setTimeout(() => {
    //   console.log(dashState, "ttttttttttttttttt")
    // }, 500);
  };

  const handlePageClick = ({ selected }) => {
    setDashState((v) => ({ ...v, searchFilter: { ...dashState?.searchFilter, filter: formik.values.filter } }));
    handleChange("page", selected + 1);
  };


  const getAllDetailsPagination = async () => {

    setDashState((v) => ({ ...v, allGigsData: { loading: true, totalGigs: 0, data: "", totalPageCount: "" } }));

    try {

      const res = await getAllGigs(dashState?.searchFilter);

      // console.log(res, "response of pagination")
      if (res?.status === 200) {
        setDashState((v) => ({
          ...v,
          driverProfile: { loading: false },
          allGigsData: {
            loading: false,
            totalGigs: res?.data?.totalGigs,
            data: res?.data?.AllGigs,
            totalPageCount: res.data.pages
          },
        }));
      }
    } catch (error) {
      setDashState((v) => ({ ...v, driverProfile: { loading: false, totalGigs: 0, data: "", totalPageCount: "" } }));
    }
  }

  useEffect(() => {
    // fetchDriverProfile();
    // fetchAllGigs(dashState?.searchFilter);
    setTimeout(() => { formik.handleSubmit() }, 10)
  }, []);

  useEffect(() => {
    getAllDetailsPagination()
  }, [dashState?.searchFilter?.page]);

  // console.log(dashState, "dashStatedashState")

  return (
    <div>
      <AppLayout>
        <div className='main-content'>

          <div className='pt-4' style={{ marginBottom: "5rem" }}>
            <div className="container">
              <h3 className="fw-bold py-4">All Gigs</h3>
            </div>
            <SearchGigs formik={formik} setDashState={setDashState} dashState={dashState} />
            {!dashState?.allGigsData?.loading && <div className='text-center py-2 my-3 light-clr fw-semibold' style={{ backgroundColor: "#F1F6FF" }}>
              {`${dashState.allGigsData.totalGigs} gigs available`}
            </div>}
            <div className='container'>
              {dashState?.allGigsData?.loading ? (
                <div className=' text-center py-2'>
                  <BlueSpinner />
                </div>
              ) : dashState?.allGigsData?.data?.length >= 1 ? (
                dashState?.allGigsData?.data?.map((el, i) => {
                  // let interval = el?.interval?.split(":");
                  // let intervalTime =
                  //   el?.pickUpDateAndTime && moment(el?.pickUpDateAndTime).add(parseInt(interval[0]), "hours").add(parseInt(interval[1]), "minutes");
                  return (
                    <Link to={`/gig-view/${el.orderId}`} className='text-decoration-none' key={`gigsData${i}`}>
                      <div className='row gx-0 d-flex justify-content-between align-items-center mb-2  border-bottom bg-white py-2 px-2'>
                        <div className='col-6'>
                          <div>
                            <p className='mb-1 p-12 text-capitalize text-muted'>
                              <Moment format='ddd. MMM. D' date={el.pickUpDateAndTime} />
                            </p>
                          </div>
                          <div className='d-flex p-12 align-items-center mb-2'>
                            <p className='m-0 text-dark opacity-75 fw-bold'>
                              <Moment format='LT ' date={el.pickUpDateAndTime} />
                            </p>
                            <div className='border-top  text-center px-2' style={{ width: 50, height: 1, lineHeight: "0.6" }}>
                              <span className='fw-bold' style={{ fontSize: 8 }}>
                                {Math.round(el.distance) + "mi"}
                              </span>
                            </div>
                            <p className='m-0 text-dark opacity-75 fw-bold'>
                              <Moment format='LT ' date={el?.dropOffTime || ""} />
                            </p>
                          </div>
                          <div>
                            <p className='fw-semibold text-muted mb-1 p-12 pe-5'>{el.pickupAddress}</p>
                          </div>
                          <div></div>
                        </div>
                        <div className='col-6 text-end'>
                        <div class="text-end"><p class="fw-bold mb-1 p-12 text-dark">#{el.orderId}</p></div>
                          <div>
                            <p className='danger-text  fw-semibold h6 mb-1 '>{"$" + parseInt(el?.priceOfRequest)?.toFixed(2)}</p>
                          </div>

                          {/* <div className='invisible'>
                            <p className='text-fade mb-1 h6 text-decoration-line-through'>
                              {"$" + (parseInt(el?.priceOfRequest)?.toFixed(2) / 0.8).toFixed(2)}
                            </p>
                          </div> */}
                          <div>
                            <p className='fw-semibold text-muted mb-1 p-12 ps-5'>{el.dropOffAddress}</p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })
              ) : (
                <div className=' text-center py-2 text-muted'>No data found</div>
              )}
            </div>
          </div>
        </div>
        <Pagination
          pageCount={dashState?.allGigsData?.totalPageCount}
          // pageCount={2} 
          onPageChange={handlePageClick}
          itemsPerPage={10} />
      </AppLayout>
    </div>
  );
};

export default AllGigs;
