import moment from 'moment';
import React from 'react'
import { useLocation } from 'react-router-dom';
import AppLayout from '../../Component/AppLayout/AppLayout';
// import Layout from '../../'
import { Loader } from '../../Utils/Loader';

function Gigview() {
  const location = useLocation();
  const gigDetails = location.state?.gigDetails
  const isZeroMins1 = (time, type, timeToAdd) => {
    const s = Number(timeToAdd)
    const tTime = moment(time).add(s, "s").format()
    const pickTime = moment(tTime).format("ddd. MMM. D (h:mm A)")
    const timeOnly = pickTime?.split("(")?.slice(-1)[0]
    const dateOnly = pickTime?.split("(")?.slice(0)[0]
    const mins = timeOnly.split(":")?.slice(-1)[0]
    if (mins.includes("00")) {
      if (type === "pick") {
        return ` ${dateOnly}, (${timeOnly.replace(":00", " ").split(")")?.slice(0)[0]})`;
      } else {
        return ` ${timeOnly.replace(":00", " ").split(")")?.slice(0)[0]}`;
      }
    } else {
      if (type === "pick") {
        return ` ${dateOnly}, (${timeOnly.split(")")?.slice(0)[0]})`;
      } else {
        return `${timeOnly.split(")")?.slice(0)[0]}`;
      }
    }
  };
 

  const isZeroMins = (time, type) => {
    const pickTime = moment(time).format("ddd. MMM. D (h:mm A)")
    const timeOnly = pickTime?.split("(")?.slice(-1)[0]
    const dateOnly = pickTime?.split("(")?.slice(0)[0]
    const mins = timeOnly.split(":")?.slice(-1)[0]

    const dropTime = moment(gigDetails?.dropOffTime).format("hh:mm A");

    if (mins.includes("00")) {
      if (type === "pick") {
        return ` ${dateOnly}, (${timeOnly.replace(":00", " ").split(")")?.slice(0)[0]})`
      } else {
        return ` ${dateOnly}, (${timeOnly.replace(":00", " ").split(")")?.slice(0)[0]})`
      }

    } else {
      if (type === "pick") {
        return ` ${dateOnly}, (${timeOnly.split(")")?.slice(0)[0]})`
      } else {
        return ` ${dateOnly}, (${timeOnly.split(")")?.slice(0)[0]})`
      }
    }
  }
  return (
    
    <AppLayout>
      
        <div className='container px-3 mb-4 pt-3 mt-5'>
          <h3 className='fw-semibold mb-4'>Gigs</h3>
          <main className="mb-4 main-content-container">
            {gigDetails && gigDetails?.loading ? (
              <div className="text-center mt-8 pt-4 ">
                <Loader />
              </div>
            ) : (
              <>
                <div className="container mb-3">
                  <div className="mt-2 pt-2 ">
                    <div
                      className="card  mb-3"
                      style={{ border: "1px solid #E9E9E9" }}
                    >
                      <div className="border-top"></div>
                      <div className="card-body px-0 pb-0">

                        <div>
                        </div>
                        <div className="row mb-2 px-3">
                          <div className="col-5 text-fade p-11">Customer Name</div>
                          <div className="col-1 text-fade">:</div>
                          <div className="col-5 p-13 fw-semibold ">
                            {gigDetails?.customerFullName || "N/A"}
                          </div>
                        </div>
                        <div className="row mb-2 px-3">
                          <div className="col-5 text-fade p-11">Phone Number</div>
                          <div className="col-1 text-fade">:</div>
                          {/* <div className="col-5 p-13 fw-semibold ">
                            {formatPhoneNumber3(gigDetails)}
                          </div> */}
                          {/* {formatPhoneNumber1(gigDetails)} */}
                        </div>
                        <div className="row mb-2 px-3">
                          <div className="col-5 text-fade p-11">Customer e-mail</div>
                          <div className="col-1 text-fade">:</div>
                          <div className="col-5 p-14 fw-semibold ">{
                            // Boolean(customerData?.data?.firstName && customerData?.data?.lastName) ? (customerData?.data?.firstName + " " + customerData?.data?.lastName) : "N/A"
                            gigDetails?.customerEmail || "N/A"
                          }</div>
                        </div>
                        <div className="row mb-2 px-3">
                          <div className="col-5 text-fade p-11">
                            License plate
                          </div>
                          <div className="col-1 text-fade">:</div>
                          <div className="col-5 p-13 fw-semibold ">
                            {gigDetails?.vehiclePlateNumber || "N/A"}
                          </div>
                        </div>
                        <div className="row mb-2 px-3">
                          <div className="col-5 text-fade p-11">Make and Model</div>
                          <div className="col-1 text-fade">:</div>
                          <div className="col-5 p-13 fw-semibold ">
                            {gigDetails?.vehicleMake}{" "}
                            {gigDetails?.vehicleModel || "N/A"}
                          </div>
                        </div>
                        <div className="row mb-2 px-3">
                          <div className="col-5 text-fade p-11">Transmission</div>
                          <div className="col-1 text-fade">:</div>
                          <div className="col-5 p-13 fw-semibold ">
                            {gigDetails?.transmission || "N/A"}
                          </div>
                        </div>
                        <div className="row mb-2 px-3">
                          <div className="col-5 text-fade p-11">Address</div>
                          <div className="col-1 text-fade">:</div>
                          <div className="col-5 p-13 fw-semibold ">
                            {gigDetails?.address || "N/A"}
                          </div>
                        </div>
                        <div className="row mb-2 px-3">
                          <div className="col-5 text-fade p-11">Distance</div>
                          <div className="col-1 text-fade">:</div>
                          <div className="col-5 p-13 fw-semibold ">
                            {gigDetails?.distance || "N/A"} miles
                          </div>
                        </div>
                        <div className="row mb-2 px-3">
                          <div className="col-5 text-fade p-11">
                            Estimated duration
                          </div>
                          <div className="col-1 text-fade">:</div>
                          <div className="col-5 p-13 fw-semibold ">
                            {gigDetails?.estimateEndTime || "N/A"}
                          </div>
                        </div>

                        <div className='row mb-2 px-3'>
                          <div className='col-5 text-fade p-11'>Estimated time arrival</div>
                          <div className='col-1 text-fade'>:</div>
                          <div className='col-5 p-13 fw-semibold'>
                            {isZeroMins1(gigDetails?.pickUpDateAndTime, "pick", gigDetails?.estimatedTimeSeconds)}
                          </div>
                        </div>

                        <div className='row mb-2 px-3'>
                          <div className='col-5 text-fade p-11'>Must arrival latest</div>
                          <div className='col-1 text-fade'>:</div>
                          <div className='col-5 p-14 fw-semibold'>{isZeroMins(gigDetails?.dropOffTime, "drop")}</div>
                        </div>

                        {gigDetails?.mileageBeforePick && (
                          <div className='row mb-2 px-3'>
                            <div className='col-5 text-fade p-11'>Mileage before pickup</div>
                            <div className='col-1 text-fade'>:</div>
                            <div className='col-5 p-13 fw-semibold'>{gigDetails?.mileageBeforePick}</div>
                          </div>
                        )}
                        {gigDetails?.mileageAfterPick && (
                          <div className='row mb-2 px-3'>
                            <div className='col-5 text-fade p-11'>Mileage after delivery</div>
                            <div className='col-1 text-fade'>:</div>
                            {/* <div className="col-5 p-13 fw-semibold px-0">{pkT1 + "," + "(" + pkT }</div> */}
                            <div className='col-5 p-13 fw-semibold'>{gigDetails?.mileageAfterPick}</div>
                          </div>
                        )}
                        <div className='row mb-2 px-3'>
                          <div className='col-5 text-fade p-11'>Images Before PickUp</div>
                          <div className='col-1 text-fade'>:</div>
                          <div className='col-5 p-14 fw-semibold'>
                            <div className='d-flex flex-wrap justify-content-between'>
                              {gigDetails?.photosBeforePick?.length > 0 ?
                                gigDetails?.photosBeforePick?.map((e, index) => {
                                  return <a href={e} target="_blank1" >
                                    <div style={{ width: 80, height: 40 }} className='mb-2'>
                                      <img src={e} alt={`carImage${index}`} className='border w-100 h-100' />
                                    </div>
                                  </a>
                                }) : "Data Not Found"}
                            </div>

                          </div>
                        </div>
                        <div className='row mb-2 px-3'>
                          <div className='col-5 text-fade p-11'>Images After Drop</div>
                          <div className='col-1 text-fade'>:</div>
                          <div className='col-5 p-14 fw-semibold'>
                            <div className='d-flex flex-wrap justify-content-between'>

                              {gigDetails?.photosAfterDeliver?.length > 0 ?
                                gigDetails?.photosAfterDeliver?.map((e, index) => {
                                  return <a href={e} target="_blank1" >
                                    <div style={{ width: 80, height: 40 }} className='mb-2'>
                                      <img src={e} alt={`carImage${index}`} className='border w-100 h-100' />
                                    </div>
                                  </a>
                                }) : "Data Not Found"}
                            </div>

                          </div>
                        </div>
                        {gigDetails?.status === "completed" && (
                          <>
                            <div className='row mb-2 px-3'>
                              <div className='col-5 text-fade p-11'>Driver name</div>
                              <div className='col-1 text-fade'>:</div>
                              <div className='col-5 p-13 fw-semibold'>{gigDetails?.driverDetails?.name}</div>
                            </div>
                            <div className='row mb-2 px-3'>
                              <div className='col-5 text-fade p-11'>Driver phone number</div>
                              <div className='col-1 text-fade'>:</div>
                              <div className='col-5 p-13 fw-semibold'>{gigDetails?.driverDetails?.phone}</div>
                            </div>
                            <div className='row mb-2 px-3'>
                              <div className='col-5 text-fade p-11'>Driver email</div>
                              <div className='col-1 text-fade'>:</div>
                              <div className='col-5 p-13 fw-semibold'>{gigDetails?.driverDetails?.email}</div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

          </main>
        </div>
      
    </AppLayout>
  )
}

export default Gigview