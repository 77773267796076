import { deleteApi, getApiWithToken, imgUploadApi, postApiWithoutToken, postApiWithToken, putApiWithToken } from "./api-interfaces";

export const loginApi = (payload) => {
  return postApiWithoutToken(`/api/admin/auth/login`, payload);
};

export const fileUploadApi = (payload) => {
  return imgUploadApi(`/api/common/image/imageUpload`, payload);
};

export const updateDriverStatusApi = (payload) => {
  return postApiWithToken(`/api/admin/info/updateStatus`, payload)
}

export const getAllDealers = (p) => {
  return getApiWithToken(`/api/admin/info/getAllDealers?page=${p.page}&limit=${p.limit}`)
}

export const getAllDrivers = (p) => {
  return getApiWithToken(`/api/admin/info/getAllDrivers?page=${p.page}&limit=${p.limit}`)
}

export const getAdminDetails = () => {
  return getApiWithToken(`/api/admin/info/getProfile`)
}

export const updateAdminDetails = (payload) => {
  return putApiWithToken(`/api/admin/info/updateMyProfile`, payload)
}

// export const getAllGigs = (p) => {
//   return getApiWithToken(
//     `/api/admin/info/getAllGigs?page=${p.page}&limit=${p.limit}&pickUpDate=${p.pickUpDate}&pickUpTime=${p.pickUpTime}&from=${p.from}&to=${p.to}&payout=${p.payout}`
//   );
// };

export const getAllGigs = (p) => {
  return getApiWithToken(
    `/api/admin/info/getAllGigs?page=${p.page}&limit=${p.limit}&pickUpDate=${p.pickUpDate}&pickUpTime=${p.pickUpTime}&from=${p.from}&to=${p.to}&payout=${p.payout}&filter=${p.filter}`
  );
};

export const fetchDealerDetails = (id) => {
  return getApiWithToken(`/api/admin/info/getDealerById?dealerId=${id}`)
}

export const fetchDriverDetails = (id) => {
  return getApiWithToken(`/api/admin/info/getDriverById?driverId=${id}`)
}

export const updateDealerDetails = (payload) => {
  return putApiWithToken(`/api/admin/info/updateDealer`, payload)
}

export const updateDriverDetails = (payload) => {
  return putApiWithToken(`/api/admin/info/updateDriver`, payload)
}

export const deleteDealer = (id) => {
  return deleteApi(`/api/admin/info/deleteDealer`, id)
}

export const getLocationInputApi = (search) => {
  return getApiWithToken(`/api/common/location/getLocation?input=${search}`)
}

export const getGigbyId = (id) => {
  return getApiWithToken(`/api/common/commonAll/getGigById?orderId=${id}`)
}

export const updateCalculation = (payload) => {
  return putApiWithToken(`/api/admin/info/updateCalculations`, payload)
}

export const getCalcualtion = () => {
  return getApiWithToken(`/api/admin/info/getCalculations`)
}

export const getStatsOfDealer = () => {
  return getApiWithToken(`/api/admin/info/statisticsOfDealer`)
}

export const getStatsOfDriver = () => {
  return getApiWithToken(`/api/admin/info/statisticsOfDriver`)
}

// To get all chat users
export const getAllUserChats = () => {
  return getApiWithToken(`/api/admin/info/getAllChat`)
}

// To get the prvious message...
export const getPreviousChat = (p) => {
  return getApiWithToken(`/api/common/commonAll/previousMessages?convoId=${p.convoId}&page=${p.page}&limit=${p.limit}`)
}