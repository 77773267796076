import React, { createContext, useContext, useState } from 'react'
import Block from "../../../Assets/image/block.svg"
import { useNavigate } from 'react-router-dom'
import { INFO_ALL_DRIVERS } from '../../../Utils/Endpoints'
import useFetch from '../../../Hooks/useFetch'
import { BlueSpinner } from '../../../Utils/Loader'
import { updateDriverStatusApi } from '../../../Api'
import { toast } from 'react-hot-toast'
import { DealerDriverContext } from '../../../AppContext/AppContext'
import { assignBucketUrl, formatPhoneNumber1 } from '../../../Utils/HelperFunctions'
import { API_BASE_URL } from '../../../Utils/BaseUrl'
import ProfilePicDemo from "../../../Assets/image/person.png"
import BlockPerson from "../../../Assets/image/block-person.svg"
import Pagination from '../../../Component/Pagination/Pagination'

const Driver = ({ setStep }) => {

   const { driverDetails, fetchDriverDetails, setDriverDetails } = useContext(DealerDriverContext)
   const [driverId, setDriverId] = useState("")

   // By using fetch hook...
   // const { data, loading, error } = useFetch(INFO_ALL_DRIVERS)

   const navigate = useNavigate()

   const handleUpdateDriverStatus = async (id, status) => {
      const payload_ = { searchFilter: { page: 1, limit: 12 } }
      try {
         const res = await updateDriverStatusApi({ driverId: id, status: status })
         if (res?.status === 200) {
            // console.log(status, "status")
            if (status === "block") {
               toast.success("Driver blocked", { id: "001" })
            } else if (status === "unblock") {
               toast.success("Driver unblocked", { id: "002" })
            } else if (status === "approved") {
               toast.success("Driver approved", { id: "003" })
            }
            fetchDriverDetails(payload_?.searchFilter)
         }
      } catch (error) {
         console.log(error)
      }
   }

   const handleChange = (name, value) => {
      setDriverDetails((v) => ({ ...v, searchFilter: { ...driverDetails?.searchFilter, [name]: value, limit: 12 } }))
   };

   const handlePageClick = ({ selected }) => {
      handleChange("page", selected + 1);
   };

   // console.log(driverDetails, "LL", driverId)

   return (
      <>
         {
            driverDetails?.loading
               ?
               <div className="justify-content-center" style={{ textAlign: "center" }}>
                  <BlueSpinner />
               </div>
               :
               (
                  <>
                     <div className='d-flex flex-wrap' >
                        {
                           driverDetails?.data?.length > 0
                              ?
                              (
                                 driverDetails?.data?.map((el, i) => {
                                    return (
                                       <React.Fragment key={`driver-${i}`}>

                                          <div className="card mb-3 me-3 border-0 driver-profile-card" style={{ boxShadow: "0px 0px 3px #E0E0E0" }}>
                                             <div className="card-body">
                                                <div className="d-flex align-items-center justify-content-between border-bottom pb-2">
                                                   <div className="d-flex align-items-center">
                                                      <div className="" style={{ height: "49px", width: "54px" }}>
                                                         <img className="rounded-2 border"
                                                            src={assignBucketUrl(el?.profilePicture)}
                                                            alt='#' style={{ width: "100%", height: "100%", objectFit: "contain", }}
                                                            onError={event => {
                                                               event.target.src = ProfilePicDemo
                                                               event.onerror = null
                                                            }}
                                                         />
                                                      </div>
                                                      <div className="ms-2">
                                                         <p className="m-0 p-12">ID : {el?.id || "-"}</p>
                                                         <p className="m-0 fw-semibold dark-clr p14">
                                                            {el?.firstName.charAt(0).toUpperCase() + el?.firstName.slice(1) + " " + el?.lastName.charAt(0).toUpperCase() + el?.lastName.slice(1)}

                                                         </p>
                                                      </div>
                                                   </div>
                                                   <div className="d-flex">
                                                      <div className="edit-icon me-2" onClick={() => { navigate(`/user-management/edit-driver-profile/${el?.id}`) }}>
                                                         <i className="bi bi-pencil-fill text-fade p-12">
                                                         </i>
                                                      </div>

                                                      {
                                                         el?.profileStatus === "block"
                                                            ?
                                                            (
                                                               <div className="edit-icon me-2" data-bs-toggle="modal" data-bs-target="#exampleModalThree"
                                                                  onClick={() => { setDriverId(el?.id) }}>
                                                                  {/* <img src={Block} alt="" />
                                                            */}
                                                                  <i className='bi bi-person-check-fill text-fade p-12'></i>
                                                               </div>
                                                            )
                                                            :
                                                            (
                                                               <div className="edit-icon me-2" data-bs-toggle="modal" data-bs-target="#exampleModal"
                                                                  onClick={() => { setDriverId(el?.id) }}>
                                                                  <img src={Block} alt="" />
                                                               </div>)
                                                      }

                                                      {
                                                         el?.profileStatus === "pending"
                                                            ?
                                                            (
                                                               <>
                                                                  <div className="edit-icon" data-bs-toggle="modal" data-bs-target="#exampleModalTwo" onClick={() => { setDriverId(el?.id) }}>
                                                                     {/* <i className="bi bi-patch-check-fill text-fade p-6"></i> */}
                                                                     <i className="bi bi-patch-check text-fade p-6"></i>
                                                                  </div>
                                                               </>
                                                            )
                                                            :
                                                            (<div className="edit-icon">
                                                               <i className="bi bi-patch-check-fill text-fade p-6" style={{ color: "green" }}></i>
                                                               {/* <i className="bi bi-patch-check text-fade p-6"></i> */}
                                                            </div>)
                                                      }
                                                   </div>
                                                </div>
                                                <div className="mt-3">
                                                   <div className="row  mb-2">
                                                      <div className="col-5 text-fade p-12">Contact no.</div>
                                                      <div className="col-1 p-12 text-fade">:</div>
                                                      <div className="col-6 text-black fw-semibold p-14 ">{el?.phone ? formatPhoneNumber1(el?.phone) || "-" : "-"}</div>
                                                   </div>
                                                   <div className="row  mb-2">
                                                      <div className="col-5 text-fade p-12">Email</div>
                                                      <div className="col-1 p-12 text-fade">:</div>
                                                      <div className="col-6 text-black fw-semibold p-14 ">{el?.email || "-"}</div>
                                                   </div>
                                                   {/* <div className="row  mb-2">
                                                      <div className="col-5 text-fade p-12">Type</div>
                                                      <div className="col-1 p-12 text-fade">:</div>
                                                      <div className="col-6 text-black fw-semibold p-14 ">Business</div>
                                                   </div> */}
                                                   <div className="row  mb-2">
                                                      <div className="col-5 text-fade p-12">Address</div>
                                                      <div className="col-1 p-12 text-fade">:</div>
                                                      <div className="col-6 text-black fw-semibold p-14 ">{el?.address || "-"}</div>
                                                   </div>
                                                   <div className="row  mb-2">
                                                      <div className="col-5 text-fade p-12">Zip code</div>
                                                      <div className="col-1 p-12 text-fade">:</div>
                                                      <div className="col-6 text-black fw-semibold p-14 ">{el?.zipCode || "-"}</div>
                                                   </div>
                                                   <div className="row  mb-2">
                                                      <div className="col-5 text-fade p-12">City</div>
                                                      <div className="col-1 p-12 text-fade">:</div>
                                                      <div className="col-6 text-black fw-semibold p-14 ">{el?.city || "-"}</div>
                                                   </div>
                                                   <div className="row  mb-2">
                                                      <div className="col-5 text-fade p-12">State</div>
                                                      <div className="col-1 p-12 text-fade">:</div>
                                                      <div className="col-6 text-black fw-semibold p-14 ">{el?.state || "-"}</div>
                                                   </div>
                                                   <div className="row  mb-2">
                                                      <div className="col-5 text-fade p-12">Driving license</div>
                                                      <div className="col-1 p-12 text-fade">:</div>
                                                      <div className="col-6 text-black fw-semibold p-14 ">{el?.drivingLicenseNo || "-"}</div>
                                                   </div>
                                                   <div className="row  mb-2">
                                                      <div className="col-5 text-fade p-12">Status</div>
                                                      <div className="col-1 p-12 text-fade">:</div>
                                                      <div className="col-6 text-black fw-semibold p-14">{el?.profileStatus || "-"}</div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>

                                       </React.Fragment>
                                    )
                                 })
                              )
                              :
                              ""
                        }
                     </div>
                  </>
               )
         }

         {
            !driverDetails?.loading && (driverDetails?.data?.length === 0 || driverDetails?.data === null)
               ?
               (
                  <div className='text-center'>
                     <h3>
                        No data found.
                     </h3>
                  </div>
               )
               :
               ("")
         }

         {/* <!-- Block Modal --> */}
         <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered px-5">
               <div className="modal-content p-3 ">
                  <div className="text-end" data-bs-dismiss="modal">
                     <img src="./assets/image/x-circle.svg" alt="" />
                  </div>
                  <div className="modal-body py-3 text-center ">
                     <div>
                        <img src={BlockPerson} alt="" />
                     </div>
                     <p className="m-0 ps-0 text-center fw-semibold text-black fs-5">Are you sure you want to block this Driver?</p>
                  </div>
                  <div className="d-flex justify-content-center">
                     <button
                        type="button"
                        className="rounded-2   py-2 px-5 border-0 text-white bg-navy-blue fw-semibold"
                        data-bs-dismiss="modal"
                        onClick={() => handleUpdateDriverStatus(driverId, "block")}
                     >
                        Yes
                     </button>
                    <button type="button" className="rounded-2 danger  py-3 px-5 border-0 ms-1 text-white fw-semibold" data-bs-dismiss="modal">No</button>
                  
                  </div>
               </div>
            </div>
         </div >

         {/* <!-- UnBlock Modal --> */}
         <div className="modal fade" id="exampleModalThree" tabIndex="-1" aria-labelledby="exampleModalLabelThree" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered px-5">
               <div className="modal-content p-3 ">
                  <div className="text-end" data-bs-dismiss="modal">
                     <img src="./assets/image/x-circle.svg" alt="" />
                  </div>
                  <div className="modal-body py-3 text-center ">
                     <div>
                        <img src={BlockPerson} alt="" />
                     </div>
                     <p className="m-0 ps-0 text-center fw-semibold text-black fs-5">Are you sure you want to unblock this Driver?</p>
                  </div>
                  <div className="d-flex justify-content-center">
                     <button
                        type="button"
                        className="rounded-2   py-2 px-5 border-0 text-white bg-navy-blue fw-semibold"
                        data-bs-dismiss="modal"
                        onClick={() => handleUpdateDriverStatus(driverId, "unblock")}
                     >
                        Yes
                     </button>
                     <button type="button" className="rounded-2 danger  py-3 px-5 border-0 ms-1 text-white fw-semibold" data-bs-dismiss="modal">No</button>
                  
                  </div>
               </div>
            </div>
         </div >

         {/* Approve Modal */}
         <div className="modal fade" id="exampleModalTwo" tabIndex="-1" aria-labelledby="exampleModalLabelTwo" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered px-5">
               <div className="modal-content p-3 ">
                  <div className="text-end" data-bs-dismiss="modal">
                     <img src="./assets/image/x-circle.svg" alt="" />
                  </div>
                  <div className="modal-body py-3 text-center">
                     <div className='d-inline-block'>
                        <div className='edit-icon' >
                           <i className="bi bi-patch-check-fill text-fade p-6" style={{ color: "green" }}></i>
                        </div>
                     </div>
                     <p className="m-0 ps-0 text-center fw-semibold text-black fs-5">Are you sure you want to approve this Driver?</p>
                  </div>
                  <div className="d-flex justify-content-center">
                     <button
                        type="button"
                        className="rounded-2 py-2 px-5 border-0 text-white bg-navy-blue fw-semibold"
                        data-bs-dismiss="modal"
                        onClick={() => handleUpdateDriverStatus(driverId, "approved")}
                     >
                        Yes
                     </button>
                     {/* <!-- <button type="button" className="rounded-2 danger  py-3 px-5 border-0 ms-1 text-white fw-semibold" data-bs-dismiss="modal">Delete</button>
                  </div> --> */}
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}

export default Driver