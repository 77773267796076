import React from 'react'
import ReactPaginate from 'react-paginate'

const Pagination = (props) => {
   return (
      <div className='pb-4 pt-4'>
         <nav>
            <ReactPaginate
               className='pagination driverPagination justify-content-center mb-5 '
               pageRangeDisplayed={4}
               marginPagesDisplayed={1}
               activeClassName={"active-class-pagination"}
               breakLabel='...'
               {...props}
               pageLabelBuilder={(t) => <button className='page-link mx-1 rounded btn btn-sm btn_primary_black'>{t}</button>}
               nextLabel={<button className='page-link mx-1 rounded btn btn-sm btn_primary_black'>{">"}</button>}
               previousLabel={<button className='page-link mx-1 rounded btn btn-sm btn_primary_black'>{"<"}</button>}
            />
         </nav>
      </div>

   )
}

export default Pagination