import React from 'react'
import { Routes, Route } from 'react-router-dom'
import PrivateRoute from "./PrivateRoute"
import Login from '../Pages/Account/Login'
import { Toaster } from "react-hot-toast"
import Dashboard from '../Pages/Dashboard/Dashboard'
import Calculation from '../Pages/Calculation/Calculation'
import Chat from "../../src/Pages/Chat"
import UserManagement from "../../src/Pages/UserManagement"
import PublicRoute from './PublicRoute'
import AdminProfile from '../Pages/AdminProfile/AdminProfile'
import AllGigs from '../Pages/AllGigs'
import EditDealerProfile from '../Pages/UserManagement/EditProfile/EditDealerProfile'
import EditDriverProfile from "../Pages/UserManagement/EditProfile/EditDriverProfile"
import GigView from '../Pages/GigView/GigView'
import PrieviewImages from "../Pages/GigView/PreviewImages"
import Gigview from '../Pages/AllGigs/Gigview'

const MainRouter = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<PublicRoute><Login /></PublicRoute>} />
        <Route exact path='/dashboard' element={<PrivateRoute><Dashboard /> </PrivateRoute>} />
        <Route path='/calculation' element={<PrivateRoute><Calculation /> </PrivateRoute>} />
        <Route path='/support-chat' element={<PrivateRoute><Chat /> </PrivateRoute>} />
        <Route path='/user-management' element={<PrivateRoute><UserManagement /> </PrivateRoute>} />
        <Route path='/user-management/edit-dealer-profile/:id' element={<PrivateRoute><EditDealerProfile /> </PrivateRoute>} />
        <Route path='/user-management/edit-driver-profile/:id' element={<PrivateRoute><EditDriverProfile /> </PrivateRoute>} />
        <Route path='/admin-profile' element={<PrivateRoute><AdminProfile /> </PrivateRoute>} />
        {/* <Route path='/gigs' element={<PrivateRoute><Gigs /></PrivateRoute>} /> */}
        <Route path='/gig-view/:id' element={<PrivateRoute><GigView /></PrivateRoute>} />
        <Route path='/preview-photos' element={<PrivateRoute><PrieviewImages /></PrivateRoute>} />
        <Route path='/all-gigs' element={<PrivateRoute><AllGigs /> </PrivateRoute>} />
        <Route path='/viewgig' element={<PrivateRoute><Gigview /> </PrivateRoute>} />
      </Routes>
      <Toaster position='top-center' reverseOrder={false} />
    </>
  )
}

export default MainRouter