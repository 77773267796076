import React, { useEffect, useState } from 'react'
import { getAdminDetails } from '../Api'
import { getToken } from '../Utils/HelperFunctions'
import { UserContext } from './AppContext'
import { io } from 'socket.io-client'
import { API_BASE_URL } from '../Utils/BaseUrl'

const UserContextState = (props) => {

   const [adminDetails, setAdminDetails] = useState({ data: null, loading: true })
   const [isLoggedIn, setIsLoggedIn] = useState(Boolean(localStorage.getItem("token")))
   const [supportChat, setSupportChat] = useState(false)
   const [socket, setSocket] = useState("");

   const token = getToken()

   const fetchAdminProfile = async () => {
      try {
         const res = await getAdminDetails()
         if (res?.status) {
            setAdminDetails({ data: res?.data, loading: false })
         }
      } catch (error) {
         setAdminDetails({ ...adminDetails, loading: false })
         console.log(error)
      }
   }

   useEffect(() => {
      if (isLoggedIn === true || token) {
         fetchAdminProfile()
      }
   }, [isLoggedIn, token])

   return (
      <UserContext.Provider
         value={{
            adminDetails,
            fetchAdminProfile,
            setIsLoggedIn,
            supportChat,
            setSupportChat,
            socket,
            setSocket,
         }}
      >
         {props.children}
      </UserContext.Provider>
   )
}

export default UserContextState