import { useEffect, useState } from 'react';
import './App.css';
import { getAdminDetails } from './Api';
import MainRouter from './MainRouter/MainRouter';
import UserContextState from './AppContext/UserContextState';

function App() {

  // const [adminDetails, setAdminDetails] = useState({ data: null, loading: true })
  // const [isLoggedIn, setIsLoggedIn] = useState(Boolean(localStorage.getItem("token")))
  // const [supportChat, setSupportChat] = useState(false)

  // const fetchAdminProfile = async () => {
  //   try {
  //     const res = await getAdminDetails()
  //     if (res?.status) {
  //       setAdminDetails({ data: res?.data, loading: false })
  //     }
  //   } catch (error) {
  //     setAdminDetails({ ...adminDetails, loading: false })
  //     console.log(error)
  //   }
  // }

  // useEffect(() => {
  //   // if (isLoggedIn === true) {
  //   fetchAdminProfile()
  //   // }
  // }, [isLoggedIn])


  return (
    <div>
      <UserContextState>
        <MainRouter />
      </UserContextState>
    </div>
  );
}

export default App;
