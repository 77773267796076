import React, { useContext, useEffect, useState } from 'react'
import { BlueSpinner } from '../../Utils/Loader'
import moment from 'moment'
import ProfilePicDemo from "../../../src/Assets/image/person.png"
import { assignBucketUrl } from '../../Utils/HelperFunctions'
import { SocketContext } from '../../AppContext/AppContext'

const ChatList = () => {

   const { selectChatUser, userChatListFromApi } = useContext(SocketContext)

   const [filterList, setFilterList] = useState({ data: [] })

   // console.log(userChatList, "<-------------------")
   // console.log(userChatListFromApi, "chatttttttttttttttttttt")

   function filterChatList(searchTerm) {

      const filteredList = userChatListFromApi?.data.filter(chat => {
         if (searchTerm === "") {
            return true
         }
         const nameMatch = chat.createrName.toLowerCase().includes(searchTerm.toLowerCase());
         // const messageMatch = chat.message.toLowerCase().includes(searchTerm.toLowerCase());
         // return nameMatch || messageMatch;
         return nameMatch;

      });

      return setFilterList((v) => ({
         ...v,
         data: filteredList || ["No user found"]
      }))
   }

   useEffect(() => {
      setFilterList({ data: userChatListFromApi?.data })
   }, [userChatListFromApi?.data])

   return (
      <>
         <div className="col-xl-4 col-12 mb-5 ">
            <div className="  h-100 ">
               <div className=" position-relative">
                  <input
                     className="form-control w-100  ps-5 py-2 input-bg border-0  bg-light"
                     placeholder="Search..."
                     onChange={(e) => {
                        filterChatList(e.target.value)
                     }}
                  />
                  <i className="bi bi-search position-absolute h5" style={{ top: "10px", left: "10px", color: "#28599B" }}></i>
               </div>
               {
                  userChatListFromApi?.loading
                     ?
                     (
                        <div className='text-center'><BlueSpinner /></div>
                     )
                     :
                     (userChatListFromApi?.data?.length === 0 && userChatListFromApi?.loading === false) ? (
                        <div className='text-center'>No users found</div>
                     ) : ("")
               }
               {
                  filterList?.data?.length > 0
                     ?
                     (
                        filterList?.data?.map?.((el, i) => {
                           // console.log(el, "iniside map")
                           return (
                              <React.Fragment key={`${i}-chat-list`}>
                                 <div className="gap-4 chat-row p-2 border-bottom bg-white mt-1"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => { selectChatUser(el) }}
                                 >
                                    <div className="list-group-item d-flex">
                                       <div className="me-4">
                                          <div className="avatar position-absolute border-profile-pic" style={{ width: "40px", height: "40px" }}>
                                             <img alt="..."
                                                // src={API_BASE_URL + "/" + el?.createrProfile}
                                                src={assignBucketUrl(el?.createrProfile)}
                                                className="rounded-circle"
                                                style={{ height: "100%", width: "100%", objectFit: "cover" }}
                                                onError={(event) => {
                                                   event.target.src = ProfilePicDemo;
                                                   event.onerror = null;
                                                }}
                                             />
                                          </div>
                                          <div className="border rounded-circle position-relative" style={{
                                             width: "10px",
                                             height: "10px",
                                             right: "-32px",
                                             bottom: "-5px",
                                             backgroundColor: "#03ca4c",
                                          }}></div>
                                       </div>
                                       <div className="flex-fill ms-3">
                                          {/* Title  */}
                                          <div className="d-flex justify-content-between align-items-center">
                                             <h6 href="#" className="d-block fw-bold p-14 mb-1 text-dark">
                                                {el?.createrName || "-"}
                                             </h6>
                                             <span className="p-10 text-dark fw-semibold">{moment(el?.updatedAt).format("HH:MM a")}</span>
                                          </div>
                                          {/* Subtitle */}
                                          <div className="d-flex justify-content-between align-item-center">
                                             <p className="fw-semibold text-dark p-10 mb-0">
                                                {el?.latestMessage || "Click to chat..."}
                                                {/* {el?.latestMessage || chat?.[chat?.length - 1]?.message || "Click to chat..."} */}
                                             </p>
                                             <p className="badge bg-navy-blue mb-0">{el?.chatType}</p>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </React.Fragment>
                           )
                        })
                     )
                     :
                     ((filterList?.data?.length === 0) && (userChatListFromApi?.loading === false)) ? "No users found" : ""
               }
            </div>
         </div>
      </>
   )
}

export default ChatList