import React, { useContext, useEffect, useRef, useState } from 'react'
import AppLayout from '../../Component/AppLayout/AppLayout'
import { UserContext } from '../../AppContext/AppContext'
import { useFormik } from 'formik'
import { updateAdminDetails } from '../../Api'
import { toast } from 'react-hot-toast'
import { updateAdminValidation } from '../../Utils/Validations'
import { assignBucketUrl, globalHandleChange, handleFileUpload } from '../../Utils/HelperFunctions'
import { API_BASE_URL } from '../../Utils/BaseUrl'
import ProfilePicDemo from "../../Assets/image/person.png"
import { BlueSpinner } from '../../Utils/Loader'

const AdminProfile = () => {

   const { adminDetails, fetchAdminProfile } = useContext(UserContext)

   const [loader, setLoader] = useState(false)

   const imgRef = useRef();
   const inputRef = useRef();

   const formikAdmin = useFormik({
      initialValues: {
         firstName: "",
         lastName: "",
         email: "",
         profilePicture: "",
      },
      validationSchema: updateAdminValidation,
      onSubmit: async () => {
         const values = formikAdmin?.values
         // console.log(values, "admin formik values")
         try {
            const res = await updateAdminDetails(values)
            if (res?.status === 200) {
               toast.success("Admin profile updated successfully")
               fetchAdminProfile()
            }
         } catch (error) {
            console.log(error)
         }
      }
   })

   const handleRemove = () => {
      inputRef.current.src = ProfilePicDemo;
      formikAdmin.setFieldValue("profilePicture", "")
   };

   useEffect(() => {
      if (adminDetails?.data !== "") {
         // formikAdmin.setFieldValue("id", adminDetails?.data?.id || "-")
         formikAdmin.setFieldValue("firstName", adminDetails?.data?.firstName || "-")
         formikAdmin.setFieldValue("lastName", adminDetails?.data?.lastName || "-")
         formikAdmin.setFieldValue("email", adminDetails?.data?.email || "-")
         formikAdmin.setFieldValue("profilePicture", adminDetails?.data?.profilePicture || "")
      }
   }, [adminDetails?.data])

   return (
      <AppLayout>
         <main className="p-3 px-5  main">
            <h3 className="fw-bold py-4">Admin profile</h3>
            <div className="row ">
               {
                  adminDetails?.loading
                     ?
                     (
                        <div className='text-center'>
                           <BlueSpinner />
                        </div>
                     )
                     :
                     (
                        <div className="col-lg-4 col-12">
                           <div className="d-flex mb-3 justify-content-between align-items-center">
                              {/* <div className="profilePic" >
                           <img src="./assets/image/x-whitebg.svg" alt="" className="XIcon" />
                           <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20(31).jpg" alt="" className="EditProfilePic" style={{ height: "100%", width: "100%", objectFit: "cover", borderRadius: "5px" }} />
                        </div>
                        <div className="ms-4">
                           <button className=" px-4 py-1 button button-bg button-color fw-semibold">
                              Upload
                           </button>
                        </div> */}

                              <div style={{ height: "50px", width: "50px", position: "relative" }} className=" ">
                                 <span className='image-section position-absolute end-0'>
                                    <i className='bi bi-x-circle' style={{ cursor: "pointer", }} onClick={handleRemove}></i>
                                 </span>
                                 <img
                                    ref={inputRef}
                                    // src={encodeURI(`${API_BASE_URL}/${formikAdmin?.values?.profilePicture}`)} 
                                    src={assignBucketUrl(formikAdmin?.values?.profilePicture || adminDetails?.data?.profilePicture)}
                                    // src="https://driver101.s3.us-west-1.amazonaws.com/upload/files/Screenshot_from_2023-04-19_12-48-53.png"
                                    alt='#' style={{ width: "100%", height: "100%", objectFit: "cover" }} className='border rounded-3 '
                                    onError={event => {
                                       event.target.src = ProfilePicDemo
                                       event.onerror = null
                                    }} />
                              </div>
                              <label htmlFor='profilePic'>
                                 <div
                                    onClick={() => {
                                       imgRef?.current?.click();
                                       // formikAdmin.setFieldValue("profilePicture", "")
                                    }}
                                    style={{ cursor: "pointer" }}
                                    className=' px-4 py-1 button button-bg button-color fw-semibold'
                                 >
                                    Upload
                                 </div>
                              </label>
                              <input type='file' id='profilePic' name='profilePicture' onChange={(e) => {
                                 handleFileUpload(e, formikAdmin, setLoader);
                                 // formikAdmin.setFieldValue("profilePicture", "") 
                              }} className='d-none' accept="image/*" />
                           </div>

                           <div className="mb-3">
                              {/* <label for="firstName" className="form-label fw-semibold p-14 ">First Name</label> */}
                              <input
                                 type="text"
                                 className="form-control "
                                 id="firstName "
                                 placeholder="Enter your first name"
                                 name="firstName"
                                 value={formikAdmin?.values?.firstName}
                                 onChange={(e) => { globalHandleChange(e, formikAdmin) }}
                              />
                              {Boolean(formikAdmin.touched.firstName && formikAdmin.errors.firstName) && <p className='text-danger mt-2'>{formikAdmin.errors.firstName}</p>}
                           </div>
                           <div className="mb-3">
                              {/* <label for="lastName" className="form-label fw-semibold p-14 ">Last Name</label> */}
                              <input
                                 type="text"
                                 className="form-control "
                                 id="lastName "
                                 placeholder="Enter your last name"
                                 name="lastName"
                                 value={formikAdmin?.values?.lastName}
                                 onChange={(e) => { globalHandleChange(e, formikAdmin) }}
                              />
                              {Boolean(formikAdmin.touched.lastName && formikAdmin.errors.lastName) && <p className='text-danger mt-2'>{formikAdmin.errors.lastName}</p>}
                           </div>

                           <div className="mb-3">
                              {/* <label htmlFor="InputEmail1" className="form-label  fw-semibold">Email</label> */}
                              <div className="position-relative">
                                 <input
                                    type="email"
                                    className="form-control"
                                    id="InputEmail1"
                                    placeholder="Enter your email "
                                    name='email'
                                    value={formikAdmin?.values?.email}
                                    disabled={true}
                                    // onChange={(e) => { globalHandleChange(e, formikAdmin) }}
                                 />
                                 <i className="bi bi-envelope-fill position-absolute icon-p  text-fade" style={{
                                    top
                                       : "17px"
                                 }}></i>
                                 {Boolean(formikAdmin.touched.email && formikAdmin.errors.email) && <p className='text-danger mt-2'>{formikAdmin.errors.email}</p>}
                              </div>
                           </div>

                           <div className=" mt-5">
                              <button className=" px-5 button button-bg button-color fw-semibold" onClick={() => { formikAdmin.handleSubmit() }}>
                                 Save
                              </button>
                           </div>
                        </div>
                     )
               }
            </div>
         </main>
      </AppLayout>
   )
}

export default AdminProfile