import React, { useEffect, useState } from 'react'
import { BlueSpinner } from '../../Utils/Loader'
import { formatPhoneNumber1 } from '../../Utils/HelperFunctions'
import AppLayout from '../../Component/AppLayout/AppLayout'
import { useNavigate, useParams } from 'react-router-dom'
import { getGigbyId } from '../../Api'
import moment from 'moment'
import { Rating } from 'react-simple-star-rating'

const GigView = () => {

   const [gigDetails, setGigDetails] = useState({ data: null, loading: true })

   const params = useParams()
   const navigate = useNavigate()

   const fetchGigDetailsById = async (id) => {
      setGigDetails({ loading: true })
      try {
         const res = await getGigbyId(id)
         if (res?.status === 200) {
            setGigDetails({ data: res?.data, loading: false })
         }
      } catch (error) {
         setGigDetails({ loading: false })
      }
   }

   const isZeroMins = (time, type) => {
      // console.log(time, "timeeeee ")
      const pickTime = moment(time).format("ddd. MMM. D (h:mm A)")
      const timeOnly = pickTime.split("(").at(-1)
      const dateOnly = pickTime.split("(").at(0)
      const mins = timeOnly.split(":").at(-1)


      if (mins.includes("00")) {
         if (type === "pick") {

            return ` ${dateOnly}, (${timeOnly.replace(":00", " ").split(")").at(0)})`
         } else {
            return ` ${timeOnly.replace(":00", " ").split(")").at(0)}`

         }

      } else {
         if (type === "pick") {
            return ` ${dateOnly}, (${timeOnly.split(")").at(0)})`
         } else {
            return `${timeOnly.split(")").at(0)}`

         }


      }
   }


   const dropTime = moment(gigDetails?.data?.dropOffTime).format("hh:mm A")

   const dt1 = dropTime.split(":")[0].replace(0, '') + ":" + dropTime.split(":")[1].replace(":00", '')
   //   console.log(dt1, "Dddddddddddddddddddd1111111")



   const isZeroMins1 = (time, type, timeToAdd) => {
      // console.log(time, "timeeeee", typeof timeToAdd)
      const s = Number(timeToAdd)
      // console.log(moment(time).add(s, "s").format(), "timeeeee WWW")

      // console.log(time,"timeeeee", timeToAdd.split(" ")[0]) 


      const tTime = moment(time).add(s, "s").format()
      const pickTime = moment(tTime).format("ddd. MMM. D (h:mm A)")
      const timeOnly = pickTime.split("(").at(-1)
      const dateOnly = pickTime.split("(").at(0)
      const mins = timeOnly.split(":").at(-1)

      if (mins.includes("00")) {
         if (type === "pick") {

            return ` ${dateOnly}, (${timeOnly.replace(":00", " ").split(")").at(0)})`
         } else {
            return ` ${timeOnly.replace(":00", " ").split(")").at(0)}`
         }

      } else {
         if (type === "pick") {
            return ` ${dateOnly}, (${timeOnly.split(")").at(0)})`
         } else {
            return `${timeOnly.split(")").at(0)}`
         }
      }
   }


   useEffect(() => {
      fetchGigDetailsById(params?.id)
   }, [params?.id])

   return (
      <>
         <AppLayout>
            <main className="mb-4 ">
               {
                  gigDetails && gigDetails?.loading ?
                     (<div className='text-center mt-8 pt-4 '>
                        <BlueSpinner />
                     </div>)
                     :
                     (<>
                        <div className="container mb-3">

                           <div className="mt-4 pt-2 ">
                              <div className="col-lg-12 col-12 d-flex ">
                                 <div className="col-9">
                                    <h3 className="fw-bold py-4">Gig information</h3>
                                 </div>
                                 <div className="col-3 py-4">
                                    <i
                                       className="bi bi-arrow-left-circle-fill"
                                       onClick={() => navigate("/all-gigs")}
                                       style={{ cursor: "pointer", float: "right" }}
                                    ></i>
                                 </div>
                              </div>

                              <div className="card  mb-3" style={{ border: "1px solid #E9E9E9" }}>
                          

                                 <div className="border-top"></div>
                                 <div className="card-body px-0 pb-0">
 

                                    <div className="light-yellow-bg py-2 px-2 mb-2 d-flex justify-content-center align-items-center">

                                       <p className="m-0 h2 fw-bold ">{gigDetails?.data?.orderId || "-"}</p>
                                    </div>
                                  
                                    <div className="border-bottom my-2"></div>
                                    <div className="row mb-2 px-3">
                                       <div className="col-5 text-fade p-11">Customer Name</div>
                                       <div className="col-1 text-fade">:</div>
                                       <div className="col-5 p-13 fw-semibold ">{gigDetails?.data?.customerFullName || "-"}</div>
                                    </div>
                                    <div className="row mb-2 px-3">
                                       <div className="col-5 text-fade p-11">Customer email</div>
                                       <div className="col-1 text-fade">:</div>
                                       <div className="col-5 p-13 fw-semibold ">{gigDetails?.data?.customerEmail || "-"}</div>
                                    </div>
                                  
                                    {/* <div className="row mb-2 px-3">
                                       <div className="col-5 text-fade p-11">Buyer</div>
                                       <div className="col-1 text-fade">:</div>
                                       <div className="col-5 p-13 fw-semibold ">Jake Buffett</div>
                                    </div> */}
                                    <div className="row mb-2 px-3">
                                       <div className="col-5 text-fade p-11">Customer Phone</div>
                                       <div className="col-1 text-fade">:</div>
                                       <div className="col-5 p-13 fw-semibold ">{gigDetails?.data?.customerPhone !== null && gigDetails?.data?.customerPhone !== "" ? formatPhoneNumber1(gigDetails?.data?.customerPhone) : "-"}</div>
                                       {/* {formatPhoneNumber1(gigDetails?.data)} */}
                                    </div>
                                    <div className="row mb-2 px-3">
                                       <div className="col-5 text-fade p-11">Customer other information</div>
                                       <div className="col-1 text-fade">:</div>
                                       <div className="col-5 p-13 fw-semibold ">{gigDetails?.data?.customerOtherInfos || "-"}</div>
                                    </div>
                                    <div className="row mb-2 px-3">
                                       <div className="col-5 text-fade p-11">Driver Name</div>
                                       <div className="col-1 text-fade">:</div>
                                       <div className="col-5 p-13 fw-semibold ">{gigDetails?.data?.driverId ? gigDetails?.data?.driverDetails?.name : "-"}</div>
                                    </div>
                                    <div className="row mb-2 px-3">
                                       <div className="col-5 text-fade p-11">Driver email</div>
                                       <div className="col-1 text-fade">:</div>
                                       <div className="col-5 p-13 fw-semibold ">{gigDetails?.data?.driverId ? gigDetails?.data?.driverDetails?.email : "-"}</div>
                                    </div>
                                    <div className="row mb-2 px-3">
                                       <div className="col-5 text-fade p-11">Driver phone</div>
                                       <div className="col-1 text-fade">:</div>
                                       <div className="col-5 p-13 fw-semibold ">{gigDetails?.data?.driverId ? formatPhoneNumber1(gigDetails?.data?.driverDetails?.phone) : "-"}</div>
                                    </div>
                                    <div className="row mb-2 px-3">
                                       <div className="col-5 text-fade p-11">License plate</div>
                                       <div className="col-1 text-fade">:</div>
                                       <div className="col-5 p-13 fw-semibold ">{gigDetails?.data?.vehiclePlateNumber || "-"}</div>
                                    </div>
                                    <div className="row mb-2 px-3">
                                       <div className="col-5 text-fade p-11">Make and Model</div>
                                       <div className="col-1 text-fade">:</div>
                                       <div className="col-5 p-13 fw-semibold ">{gigDetails?.data?.vehicleMake} {gigDetails?.data?.vehicleModel || "-"}</div>
                                    </div>
                                    <div className="row mb-2 px-3">
                                       <div className="col-5 text-fade p-11">Vehicle color</div>
                                       <div className="col-1 text-fade">:</div>
                                       <div className="col-5 p-13 fw-semibold ">{gigDetails?.data?.vehicleColor || "-"}</div>
                                    </div>
                                    <div className="row mb-2 px-3">
                                       <div className="col-5 text-fade p-11">Transmission</div>
                                       <div className="col-1 text-fade">:</div>
                                       <div className="col-5 p-13 fw-semibold ">{gigDetails?.data?.transmission || "-"}</div>
                                    </div>
                                    <div className="row mb-2 px-3">
                                       <div className="col-5 text-fade p-11">Vehicle other information</div>
                                       <div className="col-1 text-fade">:</div>
                                       <div className="col-5 p-13 fw-semibold ">{gigDetails?.data?.vehicleOtherInformation || "-"}</div>
                                    </div>
                                    <div className="row mb-2 px-3">
                                       <div className="col-5 text-fade p-11">Pickup Address</div>
                                       <div className="col-1 text-fade">:</div>
                                       <div className="col-5 p-13 fw-semibold ">{gigDetails?.data?.pickupAddress || "-"}</div>
                                    </div>
                                    <div className="row mb-2 px-3">
                                       <div className="col-5 text-fade p-11">DropOff Address</div>
                                       <div className="col-1 text-fade">:</div>
                                       <div className="col-5 p-13 fw-semibold ">{gigDetails?.data?.dropOffAddress || "-"}</div>
                                    </div>
                                    <div className="row mb-2 px-3">
                                       <div className="col-5 text-fade p-11">Distance</div>
                                       <div className="col-1 text-fade">:</div>
                                       <div className="col-5 p-13 fw-semibold ">{gigDetails?.data?.distance || "-"} miles</div>
                                    </div>
                                    <div className="row mb-2 px-3">
                                       <div className="col-5 text-fade p-11">Estimated duration</div>
                                       <div className="col-1 text-fade">:</div>
                                       <div className="col-5 p-13 fw-semibold ">{gigDetails?.data?.estimateEndTime || "-"}</div>
                                    </div>
                                    <div className="row mb-2 px-3">
                                       <div className="col-5 text-fade p-11">VIN number</div>
                                       <div className="col-1 text-fade">:</div>
                                       <div className="col-5 p-13 fw-semibold ">{gigDetails?.data?.vinNumber || "-"}</div>
                                    </div>
                                    <div className="row mb-2 px-3">
                                       <div className="col-5 text-fade p-11">Estimated time arrival</div>
                                       <div className="col-1 text-fade">:</div>
                                       <div className="col-5 p-13 fw-semibold ">{isZeroMins1(gigDetails?.data?.pickUpDateAndTime, "pick", gigDetails?.data?.estimatedTimeSeconds)}</div>
                                    </div>
                                    <div className="row mb-2 px-3">
                                       <div className="col-5 text-fade p-11">Must arrival latest</div>
                                       <div className="col-1 text-fade">:</div>
                                       <div className="col-5 p-13 fw-semibold ">{isZeroMins(gigDetails?.data?.dropOffTime, "drop")}</div>
                                    </div>
                                    <div className='row mb-2 px-3'>
                      <div className='col-5 text-fade p-11'>Images before pickup</div>
                      <div className='col-1 text-fade'>:</div>
                      <div className='col-5 p-14 fw-semibold'>
                        <div className='d-flex flex-wrap justify-content-between'>
                          {gigDetails?.data?.photosBeforePick?.length > 0 ?
                            gigDetails?.data?.photosBeforePick?.map((e, index) => {
                              return <a href={e} target="_blank1" >
                                <div style={{ width: 80, height: 40 }} className='mb-2'>
                                  <img src={e} alt={`carImage${index}`} className='border w-100 h-100' />
                                </div>
                              </a>
                            }) : "Data Not Found"}
                        </div>

                      </div>
                    </div>
                    <div className='row mb-2 px-3'>
                      <div className='col-5 text-fade p-11'>Images after drop off</div>
                      <div className='col-1 text-fade'>:</div>
                      <div className='col-5 p-14 fw-semibold'>
                        <div className='d-flex flex-wrap justify-content-between'>

                          {gigDetails?.data?.photosAfterDeliver?.length > 0 ?
                            gigDetails?.data?.photosAfterDeliver?.map((e, index) => {
                              return <a href={e} target="_blank1" >
                                <div style={{ width: 80, height: 40 }} className='mb-2'>
                                  <img src={e} alt={`carImage${index}`} className='border w-100 h-100' />
                                </div>
                              </a>
                            }) : "Data Not Found"}
                        </div>

                      </div>
                    </div>
                                    {
                                       gigDetails?.data?.rating !== "0"
                                          ?
                                          (
                                             <div className='row mb-2 px-3'>
                                                <div className='col-5 text-fade p-11'>Rating</div>
                                                <div className='col-1 text-fade'>:</div>
                                                <div className='col-5 p-10 ps-2 fw-semibold'>
                                                   <Rating
                                                      initialValue={Number(gigDetails?.data?.rating)}
                                                      allowFraction={true}
                                                      size={20}
                                                   />
                                                </div>
                                             </div>
                                          )
                                          :
                                          ("")
                                    }
                                    {/* {gigDetails?.data?.status === "active" || gigDetails?.data?.status === "booked" || gigDetails?.data?.status === "arrived" ? 
                          <>
                                <div className="d-flex align-items-center justify-content-between container my-2  border-top py-3" >
                                  <p className="m-0 fw-semibold"> <img src="./component/images/Group 2768.svg" className="img-fluid" alt=""/> Reschedule</p>
                                  <Link className="m-0 danger-text fw-semibold text-decoration-none" onClick={() => handleCancel()}>
                                    Cancel
                                  </Link>
                                </div>
                          </> :
                          "" 
                      }  */}
                                 </div>

                              </div>
                              {/* {
                                 gigDetails?.data?.status === "completed"
                                    ?
                                    (
                                       <div className='col-12 mb-2'>
                                          <button
                                             // w-50 py-4 mb-5
                                             className="button btn-primary w-100 py-3"
                                             onClick={() => {
                                                navigate("/preview-photos", {
                                                   state: {
                                                      beforeData: gigDetails?.data?.photosBeforePick,
                                                      afterData: gigDetails?.data?.photosAfterDeliver,
                                                   }
                                                })
                                             }}
                                          >
                                             Preview images
                                          </button>
                                       </div>
                                    )
                                    :
                                    ("")
                              } */}



                           </div>
                        </div>
                        {/* <div style="height: 40px;"></div>  */}
                        {/* <div className="container  my-3">
                  <button className="button  btn-primary w-100 py-3 mb-5" onClick={() => {navigateToMap()}}>
                    Track your order
                  </button>
                  <div style="height: 40px;"></div>
                </div> */}
                        {
                           // gigDetails?.data?.status === "completed" || gigDetails?.data?.status === "delivered" ? (
                           //    <>
                           //       <div className="container  my-3">
                           //          <button className="button  btn-primary w-100 py-3 mb-5" onClick={() => { navigate(`/opinion/${params?.gigId}`) }}>
                           //             Review rating
                           //          </button>
                           //       </div>
                           //    </>
                           // ) : (
                           //    <>
                           //       <div className="container  my-3">
                           //          <button className="button  btn-primary w-100 py-3 mb-5" onClick={() => { navigateToMap() }}>
                           //             Track your order
                           //          </button>
                           //          {/* <div style="height: 40px;"></div> */}
                           //       </div>
                           //    </>
                           // )
                        }

                     </>)
               }
            </main>
         </AppLayout>
      </>
   )
}

export default GigView