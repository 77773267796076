import React, { useEffect, useState } from 'react'
import Dealer from './Dealer/Dealer'
import Driver from './Driver/Driver'
import AppLayout from '../../Component/AppLayout/AppLayout'
import { getAllDealers, getAllDrivers } from '../../Api'
import { DealerDriverContext } from '../../AppContext/AppContext'
import Pagination from '../../Component/Pagination/Pagination'

const UserManagement = () => {

  const [step, setStep] = useState(0)

  const [dealerDetails, setDealerDetails] = useState({ data: null, loading: true, pageCount: "", totalDealers: 0, searchFilter: { page: 1, limit: 12 } })
  const [driverDetails, setDriverDetails] = useState({ data: null, loading: true, pageCount: "", totalDrivers: 0, searchFilter: { page: 1, limit: 12 } })

  const fetchDealerDetails = async (p) => {
    setDealerDetails({ ...dealerDetails, loading: true })
    try {
      const res = await getAllDealers(p)
      if (res?.status === 200) {
        // console.log(res?.data, "<><><><>")
        setDealerDetails({ ...dealerDetails, data: res?.data?.allDealers, loading: false, pageCount: res?.data?.pages, totalDrivers: res?.data?.totalDealers })
      }
    } catch (error) {
      setDealerDetails({ ...dealerDetails, loading: false })
      console.log(error)
    }
  }

  const handleChange = (name, value) => {
    setDealerDetails((v) => ({
      ...v,
      searchFilter: { ...dealerDetails?.searchFilter, [name]: value }
    }));
    setDriverDetails((v) => ({
      ...v,
      searchFilter: { ...driverDetails?.searchFilter, [name]: value }
    }))
  };

  const handlePageClick = ({ selected }) => {
    console.log(selected, "_+_+_+_+_+")

    handleChange("page", selected + 1);
  };

  const fetchDriverDetails = async (p) => {
    setDriverDetails({ ...driverDetails, loading: true })
    try {
      const res = await getAllDrivers(p)
      if (res?.status === 200) {
        // console.log(res, "PPPPPP")
        setDriverDetails({ ...driverDetails, data: res?.data?.allDrivers, loading: false, pageCount: res?.data?.pages, totalDrivers: res?.data?.totalDrivers })
      }
    } catch (error) {
      setDriverDetails({ ...driverDetails, loading: false })
      console.log(error)
    }
  }

  const handleSetStep = (e) => {
    setStep(parseInt(e.target.value))
  }

  useEffect(() => {
    if (step === 0) {
      // console.log(dealerDetails?.searchFilter, "<><><> ppp", driverDetails?.searchFilter)
      fetchDealerDetails(dealerDetails?.searchFilter)
    } else if (step === 1) {
      fetchDriverDetails(driverDetails?.searchFilter)
    }
  }, [step, dealerDetails?.searchFilter, driverDetails?.searchFilter])

  return (
    <>
      <AppLayout>
        <main className="p-3 px-5 main" >
          {/* <div className='container'> */}
          <h3 className="fw-bold py-2">User Management</h3>
          {/* </div> */}
          <div className="content ">
            {/* <div className='container'> */}
            <select
              className="form-select fw-semibold border-0 border-opacity-10 mb-4"
              aria-label="Default select example"
              style={{ width: "200px", boxShadow: "0px 3px 6px #00000017" }}
              onChange={(e) => handleSetStep(e)}
            >
              <option selected value="0" >Dealer details</option>
              <option value="1" >Driver details</option>
            </select>
            <div  >
              <DealerDriverContext.Provider
                value={{
                  dealerDetails,
                  driverDetails,
                  setDealerDetails,
                  setDriverDetails,
                  fetchDealerDetails,
                  fetchDriverDetails
                }}
              >
                {
                  step === 0 &&
                  <Dealer
                    setStep={setStep}
                  />
                }
                {
                  step === 1 &&
                  <Driver
                    setStep={setStep}
                  />
                }
                <Pagination
                  itemsPerPage={12}
                  onPageChange={handlePageClick}
                  pageCount={step === 0 ? dealerDetails?.pageCount : driverDetails?.pageCount}
                // pageCount={5}
                />
              </DealerDriverContext.Provider>
              {/* </div> */}

            </div>
          </div>
        </main>
      </AppLayout>
    </>
  )
}

export default UserManagement