import React, { useContext, useEffect, useRef, useState } from 'react'
import { assignBucketUrl } from '../../Utils/HelperFunctions'
import { SocketContext, UserContext } from '../../AppContext/AppContext'
import { BlueSpinner } from '../../Utils/Loader'
import ProfilePicDemo from "../../../src/Assets/image/person.png"
import { API_BASE_URL } from '../../Utils/BaseUrl'
import { useFormik } from 'formik'
import { io } from 'socket.io-client'

const ChatSection = () => {

   const { adminDetails, socket } = useContext(UserContext)
   const { chat, setChat, chatSectionLoader, socket_, setChatSectionLoader, userChatListFromApi, currentUserDetails, fetchPreviousChats, setSocket } = useContext(SocketContext)

   const [attaches2, setAttaches2] = useState({ data: null, loading: false });
   const [currMsgState, setCurrMSgState] = useState("");

   // const [chat_, setChat_] = useState([])
   let zipArr = []

   const attachmentRef = useRef(null);

   const messageRef = useRef(null)

   const scrollToBottom = () => {
      messageRef.current?.scrollIntoView({ behavior: "smooth" })
   }

   const formik = useFormik({
      initialValues: {
         message: "",
         senderId: "",
         recieverId: "",
         convoId: "",
         // isRead: false,
         attachment: {},
         senderName: "",
         recieverName: "",
         profilePicture: ""
      },
      validationSchema: "",
      onSubmit: async (values) => {

         console.log(values, "<><><>", socket)

         values.message = values?.message?.trim();

         if (!values?.message) {
            if (attaches2?.data) {
               socket.emit("send message", { ...values });
               formik.setFieldValue("attachment", "");
            }
         } else if (values?.message) {
            socket.emit("send message", { ...values });
            // socket.on("message", (i) => {
            //    console.log(i, "what is this?");
            // fetchPreviousChats({ convoId: values?.convoId, page: 1, limit: 50 })
            // })
            setCurrMSgState(values.message)
            // fetchPreviousChats({ convoId: values?.convoId, page: 1, limit: 50 })
            formik.setFieldValue("message", "");
         }
         setAttaches2({ data: null });
      },
   });

   const setDefaultChat = (formik) => {

      console.log(userChatListFromApi?.data, "++++++++", formik)

      // setConvoId(Boolean(userChatListFromApi?.data?.[0]?.convoId) && userChatListFromApi?.data?.[0]?.convoId)
      if (userChatListFromApi?.data?.length > 0) {

         formik.setFieldValue("recieverId", `${userChatListFromApi?.data?.[0]?.createrId}`)
         formik.setFieldValue("convoId", `${userChatListFromApi?.data?.[0]?.convoId}`)
         formik.setFieldValue("senderName", `${adminDetails?.data?.firstName + " " + adminDetails?.data?.lastName}`)
         formik.setFieldValue("senderId", `${adminDetails?.data?.id}`)
         formik.setFieldValue("recieverName", `${userChatListFromApi?.data?.[0]?.createrName}`)
         formik.setFieldValue("profilePicture", `${userChatListFromApi?.data?.[0]?.createrProfile}`)
         // console.log(socket_, "asasasasas")
         setSocket(io(API_BASE_URL, { transports: ["websocket"], query: { convoId: Boolean(userChatListFromApi?.data?.[0]?.convoId) && userChatListFromApi?.data?.[0]?.convoId } }))
         fetchPreviousChats({ convoId: userChatListFromApi?.data?.[0]?.convoId, page: 1, limit: 50 })
         setChatSectionLoader(false)
         if (chat?.length > 1) {
            console.log(chat?.length, "KKK")
         }
      }
      else {
         // setSocket(io(API_BASE_URL, { transports: ["websocket"], query: { convoId: "1" } }))
         // socket = io(API_BASE_URL, { transports: ["websocket"], query: { convoId: "1" } })
      }
   }

   const handleMessageSubmit = (e) => {
      e.preventDefault()
      formik.handleSubmit()
   }

   const fileCheck = (file) => {
      const fileExt = file?.split(".").at(-1);
      if (fileExt.toLowerCase() === "png" || fileExt.toLowerCase() === "jpg" || fileExt.toLowerCase() === "jpeg") {
         return (
            <a href={file} target='_blank' className='' >
               <div className='' style={{ objectFit: "", height: "100px", width: "100px" }}>
                  <img
                     style={{ height: "100px", width: "100px" }}
                     src={API_BASE_URL + "/" + file}
                     alt='placeholder'
                     onError={(event) => {
                        event.target.src = ProfilePicDemo;
                        event.onerror = null;
                     }}
                  />
               </div>
            </a>
         );
      } else {
         return (
            <a href={file} target='_blank'>
               <div className='icon icon-shape text-xl bg-black text-white'>
                  <i className='bi bi-file-earmark-fill'></i>
               </div>
            </a>
         );
      }
   };

   const handleAttachmentClick = () => {
      // attachmentRef.current.click();
      console.log("attachment uplaod is on hold...")
   };

   useEffect(() => {
      if (userChatListFromApi?.data.length > 0) {
         setDefaultChat(formik)
      }
   }, [userChatListFromApi?.data])

   useEffect(() => {
      if (currentUserDetails?.chatType !== "") {
         formik.setFieldValue("recieverId", `${currentUserDetails?.createrId}`)
         formik.setFieldValue("convoId", `${currentUserDetails?.convoId}`)
         formik.setFieldValue("senderName", `${adminDetails?.data?.firstName + " " + adminDetails?.data?.lastName}`)
         formik.setFieldValue("senderId", adminDetails?.data?.id)
      }
   }, [currentUserDetails])

   // useEffect(() => {
   //    socket.on("previous message", (message) => {
   //       // console.log(message, "previous message")
   //       // setChat({ data: message, loading: false });
   //       setChat(message);
   //       setChatSectionLoader(false)
   //    });
   // }, [socket]);

   // useEffect(() => {
   //    if (socket) {
   //       socket?.on("message", (message) => {
   //          console.log(message, "this is hereeeeeeeeeeeeeeeeee")
   //          // setChat(chat[0]?.msg ? [message] : [...chat, message]);
   //          // setChat((v) => ({ ...v, data: chat?.data ? [...chat?.data, message] : [...chat?.data, message], loading: false })) 
   //       });
   //    }
   // }, [socket]);

   useEffect(() => {
      if (socket) {
         socket.on("message", (newMsg) => {
            setChat(() => [...chat, newMsg])
         })
      }
   }, [chat])


   useEffect(() => {
      if (chatSectionLoader === false) {
         scrollToBottom()
      }
   }, [socket, chat])

   useEffect(() => {
      if (Boolean((chat?.length >= 1) && (!chat?.[0]?.msg)) === false) {
         setChatSectionLoader(true)
      }
   }, [chat])

   // useEffect(() => {
   //    fetchPreviousChats({ convoId: formik.values?.convoId, page: 1, limit: 20 })
   // }, [currMsgState])

   // console.log(formik?.values, "formikvalues", currentUserDetails, chatSectionLoader, !chat?.[0]?.msg) 

   // console.log("KOKOK", chat)

   // const uArr = [...new Set(chat)]

   // console.log(uArr)

   return (
      <>
         {
            chatSectionLoader
               ?
               (
                  <div className='col-xl-8 col-12 bg-white box-shadow chat-wrapper-admin'>
                     <div className='text-center mt-4'>
                        <BlueSpinner />
                     </div>
                  </div>
               )
               :
               (
                  <div className="col-xl-8 col-12 bg-white box-shadow chat-wrapper-admin">
                     <div className="gap-4 chat-row p-2 pb-0">
                        <div className='chat-header-top'>
                           <div
                              className="list-group-item d-flex justify-content-between align-items-center sky-blue-bg py-2 px-2">
                              <div className="d-flex">
                                 <div className="me-4">
                                    <div className="avatar position-absolute border-profile-pic" style={{ width: "40px", height: "40px" }}>
                                       <img alt="..."
                                          src={assignBucketUrl(currentUserDetails?.createrProfile || formik.values.createrProfile)}
                                          className="rounded-circle"
                                          style={{ height: "100%", width: "100%", objectFit: "cover" }}
                                          onError={(event) => {
                                             event.target.src = ProfilePicDemo;
                                             event.onerror = null;
                                          }}
                                       />
                                    </div>
                                    <div className="border rounded-circle position-relative" style={{
                                       width: "10px",
                                       height: "10px",
                                       right: "-32px",
                                       bottom: "-5px",
                                       backgroundColor: "#03ca4c",
                                    }}></div>
                                 </div>
                                 <div className="flex-fill ms-3 ">
                                    {/* Title */}
                                    <div className="d-flex justify-content-between align-items-center">
                                       <h6 href="#" className="d-block fw-bold mb-1 text-dark">
                                          {`${currentUserDetails?.createrName || formik.values.recieverName}` || "-"}
                                       </h6>
                                    </div>
                                    {/* Subtitle */}
                                    <div className="d-flex justify-content-between align-item-center">
                                       <p className="text-muted p-12 mb-0">Active Now</p>
                                    </div>
                                 </div>
                              </div>
                              <div>
                                 {/* <i className="bi bi-x-lg h6 px-3 "></i> */}
                              </div>
                           </div>
                           <div className="hold">
                              {/* <h4 className="text-center p-16 py-2">ON HOLD</h4> */}
                              <hr />
                           </div>
                        </div>
                     </div>
                     <div className="container chat-middle">
                        <div className='scroll-the-div'>
                           {
                              chat?.length > 0
                                 ?
                                 (
                                    chat?.map((el, i) => {
                                       return (
                                          <React.Fragment key={`${i}-chat`}>
                                             {
                                                el?.senderId == adminDetails?.data?.id
                                                   ?
                                                   (
                                                      <div className="d-flex justify-content-end my-4" ref={messageRef}>
                                                         <div className=" box-shadow  rounded-5 chat-bg">
                                                            {/* <p className="mb-0 rounded-5 p-12 p-2 px-4 sender">
                                                      {el?.message}
                                                   </p> */}
                                                            {Boolean(typeof el?.attachment === "string") && (
                                                               <div className="mb-0 rounded-5 p-12 p-2 px-4 sender pt-4" >
                                                                  {fileCheck(`${el?.attachment}`)}
                                                               </div>
                                                            )}
                                                            {Boolean(el?.message) && (
                                                               <p className="mb-0 rounded-5 p-12 p-2 px-4 sender" style={{ whiteSpace: "pre-line" }}>
                                                                  {el?.message}
                                                               </p>
                                                            )}
                                                         </div>
                                                         <div className="border-profile-pic ms-2" style={{ width: 30, height: 30 }}>
                                                            <img
                                                               alt="..."
                                                               // src="https://mdbootstrap.com/img/Photos/Avatars/img%20(31).jpg"
                                                               src={assignBucketUrl(adminDetails?.data?.profilePicture)}
                                                               onError={(event) => {
                                                                  event.target.src = ProfilePicDemo;
                                                                  event.onerror = null;
                                                               }}
                                                               className="rounded-circle"
                                                               style={{ height: "100%", width: "100%", objectFit: "cover" }}
                                                            />
                                                         </div>
                                                      </div>
                                                   )
                                                   :
                                                   (
                                                      <div className="d-flex justify-content-start my-4" ref={messageRef}>
                                                         <div className="border-profile-pic me-2" style={{ width: 30, height: 30 }}>
                                                            <img
                                                               alt="..."
                                                               // src="https://mdbootstrap.com/img/Photos/Avatars/img%20(31).jpg"
                                                               src={assignBucketUrl(currentUserDetails?.createrProfile)}
                                                               onError={(event) => {
                                                                  event.target.src = ProfilePicDemo;
                                                                  event.onerror = null;
                                                               }}
                                                               className="rounded-circle"
                                                               style={{ height: "100%", width: "100%", objectFit: "cover" }}
                                                            />
                                                         </div>
                                                         <div className="box-shadow rounded-5">
                                                            {/* <p className="mb-0 rounded-5 p-12 p-2 px-4 receiver ">
                                                      {e?.message}
                                                   </p> */}
                                                            {Boolean(typeof el?.attachment === "string") && (
                                                               <div className="mb-0 rounded-5 p-12 p-2 px-4 receiver pt-4" >
                                                                  {fileCheck(`${el?.attachment}`)}
                                                               </div>
                                                            )}
                                                            {Boolean(el?.message) && (
                                                               <p className="mb-0 rounded-5 p-12 p-2 px-4 receiver" style={{ whiteSpace: "pre-line" }}>
                                                                  {el?.message}
                                                               </p>
                                                            )}
                                                         </div>
                                                      </div>
                                                   )
                                             }
                                          </React.Fragment>
                                       )
                                    })
                                 )
                                 :
                                 ("")
                           }

                           {/* <div className='d-flex mt-4'>
                              <span
                                 className="d-flex align-item-center mb-4 justify-content-evenly py-1 rounded-5 px-2 ms-2"
                                 style={{ width: "50px", backgroundColor: "white" }}>
                                 <div className='dot-typing'></div>
                              </span>
                           </div> */}
                        </div>
                     </div>
                     <div className='chat-input-wrapper'>
                        <div className="border-top w-100 p-2 pb-0 mb-0 chat-input" style={{ height: "80px" }}>
                           <div className="position-relative">
                              {/* <div className='d-flex justify-content-end' style={{ maxWidth: "10px", maxHeight: "10px" }}>
                        <img
                           alt="..."
                           src={API_BASE_URL + '/' + attaches2?.data}
                           className='w-10'
                           onError={(event) => {
                              event.target.src = ProfilePicDemo;
                              event.onerror = null;
                           }}
                        />
                     </div> */}
                              <div>
                                 {attaches2?.loading ? (
                                    <>
                                       <div className='text-end mb-20 me-10'>
                                          <BlueSpinner />
                                       </div>
                                    </>
                                 ) : attaches2?.data !== null ? (
                                    <div className='position-absolute chat-attachment-preview'>
                                       <p className='text-sm'>Preview</p>
                                       {
                                          fileCheck(attaches2?.data)
                                       }
                                    </div>
                                 ) : (
                                    <div className='position-absolute attachment-preview d-none'></div>
                                 )}
                              </div>
                              <form onSubmit={(e) => handleMessageSubmit(e)}>
                                 <input
                                    type="text"
                                    placeholder="Your message here... "
                                    className="w-100 form-control py-3  input-bg1 border-0"
                                    name="message"
                                    value={formik.values.message}
                                    // onChange={(e) => { globalHandleChange(e, formik) }}
                                    onChange={formik.handleChange}
                                 />
                                 <div className="position-absolute chat-icon d-flex">
                                    {/* <div className=" attachment me-2" style={{ cursor: "pointer" }}> 
                                       <i className="bi bi-paperclip" style={{ color: "grey", marginTop: "3px" }} onClick={handleAttachmentClick}></i>
                                       <input
                                          id='chat_attachment'
                                          hidden={true}
                                          type='file'
                                          name='attachment' 
                                          ref={attachmentRef}
                                       />
                                    </div> */}
                                    <div className="send-button me-2" onClick={(e) => {
                                       if (formik.values.message !== "") {
                                          handleMessageSubmit(e)
                                       }
                                    }}
                                       style={{ cursor: "pointer" }}
                                    >
                                       {/* <img src="/assets/image/send.svg" alt="" /> */}
                                       <i className="bi bi-send" style={{ color: "white", marginTop: "3px" }}></i>
                                    </div>
                                 </div>
                              </form>
                           </div>
                        </div>
                     </div>
                  </div>
               )
         }
      </>
   )
}

export default ChatSection