import React from 'react'
import Sidebar from '../Sidebar/Sidebar'
import Topbar from '../Topbar/Topbar'

const AppLayout = ({ children }) => {
   return (
      <div className="row g-0">
         <Sidebar />
         <div className='main-container'>
            <Topbar />
            <div className=''>{children}</div>
         </div>
      </div>
   )
}

export default AppLayout