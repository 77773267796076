import React, { useContext, useEffect, useState } from "react";
import DeleteFade from "../../../Assets/image/delete-fade.svg";
import { useNavigate } from "react-router-dom";
import { BlueSpinner } from "../../../Utils/Loader";
import { assignBucketUrl, formatPhoneNumber1 } from "../../../Utils/HelperFunctions";
import { DealerDriverContext } from "../../../AppContext/AppContext";
import { deleteDealer, getAllDealers } from "../../../Api";
import { toast } from "react-hot-toast";
import DeleteSvg from "../../../Assets/image/delete-red.svg";
import ProfilePicDemo from "../../../Assets/image/person.png";
import { API_BASE_URL } from "../../../Utils/BaseUrl";

const Dealer = ({ children }) => {
   const { dealerDetails, fetchDealerDetails, setDealerDetails } =
      useContext(DealerDriverContext);
   const [dealerId, setDealerId] = useState("");
   const navigate = useNavigate();


   // By using hook...
   // const { data, loading, error } = useFetch(INFO_ALL_DEALERS)

   const handleDeleteDealer = async (id) => {
      const payload_ = { searchFilter: { page: 1, limit: 12 } };
      try {
         const res = await deleteDealer({ dealerId: id });
         if (res?.status === 200) {
            toast.success("Dealer deleted successfully");
            fetchDealerDetails(payload_?.searchFilter);
         }
      } catch (error) {
         console.log(error);
      }
   };

   const handleChange = (name, value) => {

      setDealerDetails((v) => ({
         ...v,
         searchFilter: { ...dealerDetails?.searchFilter, [name]: value }
      }));
   };

   const handlePageClick = ({ selected }) => {
      handleChange("page", selected + 1);
   };


   const getDealerDetailsPagination = async (val) => {

      //   const [dealerDetails, setDealerDetails] = useState({ data: null, loading: true, pageCount: "", totalDealers: 0, searchFilter: { page: 1, limit: 12 } })

      setDealerDetails((v) => ({
         ...v,
         ...dealerDetails, loading: true, data: null, pageCount: "", totalDealers: 0
      }));

      try {

         const res = await getAllDealers(val);


         if (res?.status === 200) {
            setDealerDetails((v) => ({
               ...v,
               // searchFilter: dealerDetails?.searchFilter,
               data: res?.data?.allDealers,
               loading: false,
               pageCount: res?.data?.pages,
               totalDealers: res?.data?.totalDealers
            }));
         }
      } catch (error) {
         setDealerDetails((v) => ({ ...v, ...dealerDetails, loading: false }));
      }
   }

   useEffect(() => {
      getDealerDetailsPagination(dealerDetails?.searchFilter)
   }, [dealerDetails?.searchFilter?.page])



   return (
      <>
         {dealerDetails?.loading ? (
            <div className="" style={{ textAlign: "center" }}>
               <BlueSpinner />
            </div>
         ) : (
            <>
               <div className='d-flex flex-wrap' >
                  {dealerDetails?.data?.length > 0
                     ? dealerDetails?.data?.map((el, i) => {
                        return (
                           <React.Fragment key={`dealer-${i}`}>
                              <div
                                 className="card mb-3 me-3 border-0 dealer-profile-card"
                                 style={{ boxShadow: "0px 0px 3px #E0E0E0" }}
                              >
                                 <div className="card-body">
                                    <div className="d-flex align-items-center justify-content-between border-bottom pb-2">
                                       <div className="d-flex align-items-center">
                                          <div
                                             className=""
                                             style={{ height: "49px", width: "54px" }}
                                          >
                                             {/* <img className="rounded-2"
                                                            src="https://media.istockphoto.com/id/1300972574/photo/millennial-male-team-leader-organize-virtual-workshop-with-employees-online.jpg?b=1&s=170667a&w=0&k=20&c=96pCQon1xF3_onEkkckNg4cC9SCbshMvx0CfKl2ZiYs="
                                                            // src={API_BASE_URL + `/${el?.profilePicture}`}
                                                            alt="" style={{ height: "100%", width: "100%", objectFit: "cover" }} /> */}
                                             <img
                                                className="rounded-2 border"
                                                src={assignBucketUrl(el?.profilePicture)}
                                                alt="#"
                                                style={{
                                                   width: "100%",
                                                   height: "100%",
                                                   objectFit: "contain",
                                                }}
                                                onError={(event) => {
                                                   event.target.src = ProfilePicDemo;
                                                   event.onerror = null;
                                                }}
                                             />
                                          </div>
                                          <div className="ms-2">
                                             <p className="m-0 p-12">ID : {el?.id || "-"}</p>
                                             <p className="m-0 fw-semibold dark-clr p14">
                                                {/* {el?.firstName + " " + el?.lastName} */}
                                                {el?.firstName.charAt(0).toUpperCase() + el?.firstName.slice(1) + " " + el?.lastName.charAt(0).toUpperCase() + el?.lastName.slice(1)}

                                             </p>
                                          </div>
                                       </div>
                                       <div className="d-flex">
                                          <div
                                             className="edit-icon me-2"
                                             onClick={() => {
                                                navigate(`/user-management/edit-dealer-profile/${el.id}`);
                                             }}
                                          >
                                             <i className="bi bi-pencil-fill text-fade p-12"></i>
                                          </div>
                                          <div
                                             className="edit-icon"
                                             data-bs-toggle="modal"
                                             data-bs-target="#exampleModal"
                                             onClick={() => {
                                                setDealerId(el?.id);
                                             }}
                                          >
                                             <img
                                                src={DeleteFade}
                                                style={{ height: "70%", width: "100%" }}
                                                alt=""
                                             />
                                          </div>
                                       </div>
                                    </div>
                                    <div className="mt-3 card-content">
                                       <div className="row  mb-2">
                                          <div className="col-5 text-fade p-12">
                                             Contact no.
                                          </div>
                                          <div className="col-1 p-12 text-fade">:</div>
                                          <div className="col-6 text-black fw-semibold p-14 ">
                                             {el?.phone ? formatPhoneNumber1(el?.phone) || "-" : "-"}
                                          </div>
                                       </div>
                                       <div className="row  mb-2">
                                          <div className="col-5 text-fade p-12">Email</div>
                                          <div className="col-1 p-12 text-fade">:</div>
                                          <div className="col-6 text-black fw-semibold p-14 ">
                                             {el?.email || "-"}
                                          </div>
                                       </div>
                                       <div className="row  mb-2">
                                          <div className="col-5 text-fade p-12">Type</div>
                                          <div className="col-1 p-12 text-fade">:</div>
                                          <div className="col-6 text-black fw-semibold p-14 ">
                                             {el?.isBusiness === false ? "Individual" : "Business"}
                                          </div>
                                       </div>
                                       <div className="row  mb-2">
                                          <div className="col-5 text-fade p-12">Address</div>
                                          <div className="col-1 p-12 text-fade">:</div>
                                          <div className="col-6 text-black fw-semibold p-14 ">
                                             {el?.address || "-"}
                                          </div>
                                       </div>
                                       <div className="row  mb-2">
                                          <div className="col-5 text-fade p-12">Zip code</div>
                                          <div className="col-1 p-12 text-fade">:</div>
                                          <div className="col-6 text-black fw-semibold p-14 ">
                                             {el?.zipCode || "-"}
                                          </div>
                                       </div>
                                       <div className="row  mb-2">
                                          <div className="col-5 text-fade p-12">City</div>
                                          <div className="col-1 p-12 text-fade">:</div>
                                          <div className="col-6 text-black fw-semibold p-14 ">
                                             {el?.city || "-"}
                                          </div>
                                       </div>
                                       <div className="row  mb-2">
                                          <div className="col-5 text-fade p-12">State</div>
                                          <div className="col-1 p-12 text-fade">:</div>
                                          <div className="col-6 text-black fw-semibold p-14 ">
                                             {el?.state || "-"}
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </React.Fragment>
                        );
                     })
                     : ""}
               </div>
            </>
         )}
         {!dealerDetails?.loading &&
            (dealerDetails?.data?.length === 0 || dealerDetails?.data === null) ? (
            <div className="d-block">
               <h3>No data found.</h3>
            </div>
         ) : (
            ""
         )}

         {/* {!dealerDetails?.loading ? (
            <Pagination
               itemsPerPage={12}
               onPageChange={handlePageClick}
               pageCount={dealerDetails?.pageCount}
               // pageCount={5}
            />
         ) : (
            ""
         )} */}

         {/* <!-- Modal --> */}
         <div
            className="modal "
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
         >
            <div className="modal-dialog  modal-dialog-centered px-5">
               <div className="modal-content p-3 position-relative">
                  <div className="delete-icon p-2">
                     <img src={DeleteSvg} alt="" />
                  </div>

                  <div className="text-center mt-4">
                     {/* <!-- <h1 className="modal-title fs-5 fw-bold" id="exampleModalLabel">Sign Out?</h1> --> */}
                  </div>
                  <div className="modal-body py-3 ps-0 text-center fw-semibold text-black fs-5">
                     Are you sure you want to delete this Dealer?
                  </div>

                  <div className="text-center text-fade p-12">
                     Lorem ipsum dolor sit, amet consectetur adipisicing elit. Animi
                     cum porro ut magnam aut! Explicabo laboriosam fuga hic error
                     perferendis assumenda dolorum dolore cum deserunt, endi quia? Et,
                     voluptates magnam?
                  </div>

                  <div className="d-flex justify-content-end mt-3">
                     <button
                        type="button"
                        className="rounded-2   py-2 px-4 border-0 text-fade fw-semibold me-3"
                        data-bs-dismiss="modal"
                     >
                        Cancel
                     </button>
                     <button
                        type="button"
                        className="rounded-2 danger  py-2 px-4 border-0 ms-1 text-white fw-semibold"
                        data-bs-dismiss="modal"
                        onClick={() => {
                           handleDeleteDealer(dealerId);
                        }}
                     >
                        Delete
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default Dealer;
