import React, { useEffect, useState } from 'react'
import LogoImg from "../../Assets/image/Mask Group 2259.png"
import AppLayout from '../../Component/AppLayout/AppLayout'
import { useFormik, validateYupSchema } from 'formik'
import { getCalcualtion, updateCalculation } from '../../Api'
import { globalHandleChange } from '../../Utils/HelperFunctions'
import { toast } from 'react-hot-toast'
import { BlueSpinner } from '../../Utils/Loader'

const Calculation = () => {

   const [calDetails, setCalDetails] = useState({ data: null, loading: true })

   const calculationFormik = useFormik({
      initialValues: {
         base: '',
         a: "",
         b: '',
         c: "",
      },
      validationSchema: "",
      onSubmit: async (values) => {
         try {
            const res = await updateCalculation(values)
            if (res?.status === 200) {
               toast.success("Prices updated successfully")
               fetchCalculationDetails()
            }
         } catch (error) {
            console.log(error)
         }
      }
   })

   const fetchCalculationDetails = async () => {
      try {
         const res = await getCalcualtion()
         if (res?.status === 200) {
            setCalDetails((v) => ({ ...v, data: res?.data?.[0], loading: false }))
         }
      } catch (error) {
         setCalDetails((v) => ({ ...v, loading: false }))
         console.log(error)
      }
   }

   useEffect(() => {
      fetchCalculationDetails()
   }, [])

   useEffect(() => {
      if (calDetails?.data !== (null || "")) {
         calculationFormik.setFieldValue("base", calDetails?.data?.base)
         calculationFormik.setFieldValue("a", calDetails?.data?.a)
         calculationFormik.setFieldValue("b", calDetails?.data?.b)
         calculationFormik.setFieldValue("c", calDetails?.data?.c)
      }
   }, [calDetails?.data])

   return (
      <>
         <AppLayout>
            <main className="p-3 ps-5 mt-3 main">

               <h3 className="fw-bold py-4">Calculation</h3>
               <div className="content mt-3  ">

                  <div className="mt-3 row justify-content-between">
                     <div className="col-md-6">
                        <div className="card mb-3 border-0 me-5 bg-white" style={{ minHeight: "750px", boxShadow: "0px 0px 3px #E0E0E0" }}>
                           <div className="card-header border-bottom-0 py-3">
                              <div className="row justify-content-center fw-semibold ">
                                 <div className="col-md-6 text-center">Miles</div>
                                 <div className="col-md-6 text-center">Calculation</div>
                              </div>
                           </div>
                           <div className="card-body px-0 pt-0">
                              <div className="  border-bottom py-3">
                                 <div className="row justify-content-center text-fade ">
                                    <div className="col-md-6 text-center">0-25 miles</div>
                                    <div className="col-md-6 text-center">$Base+$A* miles</div>
                                 </div>
                              </div>
                              <div className="border-bottom py-3">
                                 <div className="row justify-content-center text-fade ">
                                    <div className="col-md-6 text-center">25-120 miles</div>
                                    <div className="col-md-6 text-center">$Base+$B* miles</div>
                                 </div>
                              </div>
                              <div className="   py-3">
                                 <div className="row justify-content-center text-fade ">
                                    <div className="col-md-6 text-center">120+ miles</div>
                                    <div className="col-md-6 text-center">$Base+$C* miles</div>
                                 </div>
                              </div>
                              <div className="mt-3">


                              </div>

                           </div>
                        </div>
                     </div>

                     <div className="col-md-6">
                        <div className="card mb-3 border-0 me-5 bg-white" style={{ minHeight: "750px", boxShadow: "0px 0px 3px #E0E0E0" }}>
                           {
                              calDetails?.loading
                                 ?
                                 <div className='text-center py-2'>
                                    <BlueSpinner />
                                 </div>
                                 :
                                 <div className="card-body ">
                                    <div className="d-flex justify-content-between">

                                       <div className="">
                                          <div className="fw-bold">
                                             <p className="m-0 h5 fw-bold mb-3">
                                                For now $Base = ${calDetails?.data?.base || "-"}
                                             </p>
                                             <p className="m-0 h5 fw-bold mb-3">
                                                A = {calDetails?.data?.a || "-"}
                                             </p>
                                             <p className="m-0 h5 fw-bold mb-3">
                                                B = {calDetails?.data?.b || "-"}
                                             </p>
                                             <p className="m-0 h5 fw-bold mb-3">
                                                C = {calDetails?.data?.c || "-"}
                                             </p>
                                          </div>

                                       </div>
                                       <div className="">
                                          <div className="edit-icon" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                             <i className="bi bi-pencil-fill text-fade  "></i>
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                           }
                        </div>
                     </div>




                  </div>
               </div>
            </main>


            {/* Modal  */}
            <div className="modal " id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
               <div className="modal-dialog  modal-dialog-centered px-5">
                  <div className="modal-content p-3 ">

                     <div className="modal-header px-0">
                        <h5 className="modal-title fw-semibold">Edit calculation</h5>
                        <div className="text-end modal-title" data-bs-dismiss="modal" aria-label="Close">
                           <img src="./assets/image/x-circle.svg" alt="" />
                        </div>
                     </div>
                     <div className="modal-body  text-start px-0">
                        <form action="">

                           <div className="mb-3">
                              <label htmlFor="exampleFormControlInput1" className="form-label mb-0 fw-semibold p-14">$Base Value</label>
                              <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Enter value" name="base" value={calculationFormik?.values?.base} onChange={(e) => globalHandleChange(e, calculationFormik)} />
                           </div>

                           <div className="mb-3">
                              <label htmlFor="exampleFormControlInput1" className="form-label mb-0 fw-semibold p-14">"A" Value</label>
                              <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Enter value" name="a" value={calculationFormik?.values?.a} onChange={(e) => globalHandleChange(e, calculationFormik)} />
                           </div>

                           <div className="mb-3">
                              <label htmlFor="exampleFormControlInput1" className="form-label mb-0 fw-semibold p-14">"B" Value</label>
                              <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Enter value" name="b" value={calculationFormik?.values?.b} onChange={(e) => globalHandleChange(e, calculationFormik)} />
                           </div>
                           <div className="mb-3">
                              <label htmlFor="exampleFormControlInput1" className="form-label mb-0 fw-semibold p-14">"C" Value</label>
                              <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Enter value" name="c" value={calculationFormik?.values?.c} onChange={(e) => globalHandleChange(e, calculationFormik)} />
                           </div>
                        </form>
                     </div>
                     <div className="d-flex justify-content-center">
                        <button type="button" className="rounded-2 w-100  py-2 px-5 border-0 text-white bg-dark fw-semibold"
                           data-bs-dismiss="modal"
                           onClick={() => { calculationFormik?.handleSubmit() }}
                        >
                           Save
                        </button>
                        {/* <button type="button" className="rounded-2 danger  py-3 px-5 border-0 ms-1 text-white fw-semibold" data-bs-dismiss="modal">Delete</button> */}
                     </div>
                  </div>
               </div>
            </div>
         </AppLayout>
      </>
   )
}

export default Calculation