import React, { useCallback, useState } from "react";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import TimePicker from "react-time-picker";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import { useNavigate } from 'react-router-dom';
import { debounce } from "lodash";
import { getGigbyId } from "../../Api";

const SearchGigs = ({ formik, setDashState, dashState }) => {

  const [showFilter, setShowFilter] = useState("Listed")
  const [gigSearch, setGigSearch] = useState({ label: "search gig by id.." })
  const navigate = useNavigate();
  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className='custom-css-class'>Data Not Found</span>
      </components.NoOptionsMessage>
    );
  };

  const handleSearchGig = async (search) => {
    if (search) {
      try {
        setGigSearch({ label: search })
        const res = await getGigbyId(search);
        if (res.status === 200) {
          return res.data?.orderId ? [{
            label: res.data?.orderId,
            value: res.data,
          }] : null
        }
        return null
      } catch (error) {
        return null;
      }
    }
  };

  const gigDetailbySearching = useCallback(
    debounce((inputText, callback) => {
      handleSearchGig(inputText).then((options) => callback(options));
    }, 500),
    []
  );

  return (

    <form onSubmit={formik.handleSubmit}>
      <div className='container'>
        <div className='row mb-2'>
          <div className='mt-2  col-lg-6 col-md-6 col-6'>
            <input
              value={formik.values.from}
              name='from'
              onChange={(e) => formik.handleChange(e)}
              type='text'
              className='form-control py-2'
              placeholder='From'
            />
          </div>
          <div className=' mt-2 col-lg-6 col-md-6 col-6'>
            <input
              value={formik.values.to}
              name='to'
              onChange={(e) => formik.handleChange(e)}
              type='text'
              className='form-control py-2'
              placeholder='To'
            />
          </div>
        </div>
        <div className='row mb-2'>
          <div className=' my-1 col-lg-3 col-md-3 col-sm-6 col-6'>
            <DatePicker
              selected={formik.values.pickUpDate}
              name='pickUpDate'
              onChange={(e) => {
                formik.setFieldValue("pickUpDate", e)
              }
              }
              className='form-control py-2'
              isClearable={formik.values.pickUpDate === "" ? false : true}
              placeholderText='Pickup Date'
              dateFormat='P'
            />
          </div>

          <div className='my-1 col-lg-3 col-md-3 col-sm-6 col-6'>
            <div className=''>
              <TimePicker
                className='form-control pt-0 4rem p-0'
                format='h:m a'
                openClockOnFocus={false}
                hourPlaceholder='hh'
                minutePlaceholder='mm'
                onChange={(e) => formik.setFieldValue("pickUpTime", e)}
                value={formik.values.pickUpTime}
                name='pickUpTime'
                minuteAriaLabel='Minute'
                clockIcon={null}
              />
            </div>
          </div>
          <div className='  my-1  col-lg-6 col-md-6 col-sm-12 '>
            <input
              value={formik.values.payout}
              name='payout'
              onChange={(e) => formik.handleChange(e)}
              type='text'
              className='form-control py-2'
              placeholder='Payout'
            />
          </div>
        </div>
        <div className='row mb-2'>
          <div className='col-6'>
            <div className='dropdown mb-3'>
              <button
                className='form-control d-flex justify-content-between align-items-center dropdown-toggle py-2'
                type=''
                data-bs-toggle='dropdown'
                aria-expanded='false'>
                <div>
                  <i className='bi bi-filter me-2'></i>
                  <span className=' p-16 fw-semibold'> {showFilter || "Filter"}</span>
                </div>
              </button>
              <ul className='dropdown-menu w-100 py-0'>
                <li className='border-bottom'>
                  <Link className='dropdown-item' to=''>
                    <div className='form-check d-flex align-items-center' onClick={() => {
                      setDashState((v) => ({
                        ...v,
                        searchFilter: { ...dashState?.searchFilter, filter: '' }
                      }));
                      formik.setFieldValue("filter", "")
                      setShowFilter("Listed")
                    }}>
                      <input className='form-check-input me-2' type='radio' name='flexRadioDefault' id='flexRadioDefault1' />
                      <label className='form-check-label text-dark p-16 py-1 ' htmlFor='flexRadioDefault1'>
                        Listed
                      </label>
                    </div>
                  </Link>
                </li>
                <li className='border-bottom'>
                  <Link className='dropdown-item' to=''>
                    <div className='form-check d-flex align-items-center' onClick={() => {
                      setDashState((v) => ({
                        ...v,
                        searchFilter: { ...dashState?.searchFilter, filter: '' }
                      }));
                      formik.setFieldValue("filter", "active")
                      setShowFilter("Active")
                    }}>
                      <input className='form-check-input me-2' type='radio' name='flexRadioDefault' id='flexRadioDefault1' />
                      <label className='form-check-label text-dark p-16 py-1  text-muted' htmlFor='flexRadioDefault1'>
                        Active
                      </label>
                    </div>
                  </Link>
                </li>
                <li className='border-bottom'>
                  <Link className='dropdown-item' to=''>
                    <div className='form-check d-flex align-items-center' onClick={() => {
                      setDashState((v) => ({
                        ...v,
                        searchFilter: { ...dashState?.searchFilter, filter: '' }
                      }));
                      formik.setFieldValue("filter", "booked")
                      setShowFilter("Booked")
                    }}>
                      <input className='form-check-input me-2' type='radio' name='flexRadioDefault' id='flexRadioDefault1' />
                      <label className='form-check-label text-dark p-16 py-1  text-muted' htmlFor='flexRadioDefault1'>
                        Booked
                      </label>
                    </div>
                  </Link>
                </li>
                {/* <li className='border-bottom'>
                  <Link className='dropdown-item' to=''>
                    <div className='form-check d-flex align-items-center' onClick={() => {
                      setDashState((v) => ({
                        ...v,
                        searchFilter : {...dashState?.searchFilter, filter : ''}
                    }));
                    formik.setFieldValue("filter", "")
                    }}>
                      <input className='form-check-input me-2' type='radio' name='flexRadioDefault' id='flexRadioDefault1' />
                      <label className='form-check-label text-dark p-16 py-1  text-muted' htmlFor='flexRadioDefault1'>
                        Listed
                      </label>
                    </div>
                  </Link>
                </li> */}
                <li className='border-bottom'>
                  <Link className='dropdown-item' to=''>
                    <div className='form-check d-flex align-items-center' onClick={() => {
                      setDashState((v) => ({
                        ...v,
                        searchFilter: { ...dashState?.searchFilter, filter: '' }
                      }));
                      formik.setFieldValue("filter", "completed")
                      setShowFilter("Delivered")
                    }}>
                      <input className='form-check-input me-2' type='radio' name='flexRadioDefault' id='flexRadioDefault1' />
                      <label className='form-check-label text-dark p-16 py-1  text-muted' htmlFor='flexRadioDefault1'>
                        Delivered
                      </label>
                    </div>
                  </Link>
                </li>
                <li className='border-bottom'>
                  <Link className='dropdown-item' to=''>
                    <div className='form-check d-flex align-items-center' onClick={() => {
                      setDashState((v) => ({
                        ...v,
                        searchFilter: { ...dashState?.searchFilter, filter: 'cancelled' }
                      }));
                      formik.setFieldValue("filter", "cancelled")
                      setShowFilter("Cancelled")
                    }}>
                      <input className='form-check-input me-2' type='radio' name='flexRadioDefault' id='flexRadioDefault1' />
                      <label className='form-check-label text-dark p-16 py-1  text-muted' htmlFor='flexRadioDefault1'>
                        Cancelled
                      </label>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-6'>
            <div >
              {<><form >
                <AsyncSelect
                  components={{ NoOptionsMessage }}
                  className=" "
                  // defaultInputValue="Select your location"
                  placeholder='search gig by id..'
                  // styles={SelectStyle}
                  cacheOptions
                  loadOptions={gigDetailbySearching}
                  onChange={(f) => {
                    navigate(`/gig-view/${f?.value?.orderId}`)
                  }}
                  value={gigSearch}
                  defaultOptions
                  name='pickupAddress'
                  onFocus={() => {
                    setGigSearch({ label: "search gig by id.." })
                  }}
                />
              </form>
              </>
              }
            </div>
          </div>
          <div className='my-3'>
            <button type='submit' className='button btn-primary w-100 py-3 h3 fw-normal'>
              Search gigs
            </button>
          </div>{" "}
        </div>
      </div>
    </form>
  );
};

export default SearchGigs;
