import React, { useContext } from 'react'
import { assignBucketUrl, removeToken } from '../../Utils/HelperFunctions'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../../AppContext/AppContext'
import ProfilePicDemo from "../../Assets/image/person.png"
import { ImgLoader } from '../../Utils/Loader'
import { capitalize } from 'lodash'

const Topbar = () => {

   const { adminDetails } = useContext(UserContext)

   const navigate = useNavigate()

   // console.log(adminDetails?.data, "reerererer")

   return (
      <>
         <div className="topbar d-flex align-items-center justify-content-end">
            <div className="mx-5">
               <ul className="navbar-nav mx-auto d-flex flex-row">
                  {/* <!-- Avatar --> */}
                  <li className="nav-item dropdown">
                     <a
                        className="nav-link dropdown-toggle  d-flex align-items-center"
                        href="#"
                        id="navbarDropdownMenuLink"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false">
                        {
                           adminDetails?.loading
                              ?
                              (
                                 <div className='pe-2'>
                                    <ImgLoader />
                                 </div>
                              )
                              :
                              (
                                 <div className='border-profile-pic'>
                                    <img
                                       src={assignBucketUrl(adminDetails?.data?.profilePicture)}
                                       className="rounded-circle"
                                       style={{ width: "40px", height: "40px", objectFit: "cover" }}
                                       alt=""
                                       onError={(e) => {
                                          e.target.src = ProfilePicDemo
                                          e.onError = null
                                       }}
                                    />
                                 </div>
                              )
                        }
                        {/* <span className="px-2">My Profile</span> */}
                        <span className="px-2">{capitalize(adminDetails?.data?.firstName) + " " + capitalize(adminDetails?.data?.lastName)}</span>

                     </a>

                     <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuLink">
                        <li><a className="dropdown-item" href="#" onClick={() => { navigate("/admin-profile") }} >My profile</a></li>

                        <li><a className="dropdown-item" href="#" onClick={() => { removeToken() }}>Logout</a></li>
                     </ul>
                  </li>
               </ul>
            </div>
         </div>
      </>
   )
}

export default Topbar