import React, { useContext } from "react";
import LogoImg from "../../Assets/image/Mask Group 2259.png";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext} from "../../AppContext/AppContext";
import SidebarItem from "./SideBarItem";

const Sidebar = () => {
  const { setSupportChat } = useContext(UserContext);
  const location = useLocation()
  const supportClicked = () => {
    setSupportChat(true);
    setTimeout(() => {
      navigate("/support-chat");
    }, 100);
  };

  const navigate = useNavigate();

  return (
    <>
      <div className="sidebar px-3">
        <div className="text-center m-auto py-5" style={{ width: "100px" }}>
          <img src={LogoImg} alt="" style={{ width: "100%", height: "100%" }} />
        </div>
        <div>
          <SidebarItem link="/dashboard">
            <i className="bi bi-file-bar-graph me-2"></i>Statistics
          </SidebarItem>
          <SidebarItem link="/calculation">
            <i className="bi bi-calculator me-2"></i>Calculation
          </SidebarItem>
          <SidebarItem link="/all-gigs">
            <i className="bi bi-list me-2"></i>All Gigs
          </SidebarItem>
          <SidebarItem link="/user-management">
            <i className="bi bi-person me-2"></i>User Management
          </SidebarItem>
          {/* <div className="w-100 mb-2">
            <button className="side-button" onClick={() => supportClicked()}>
              <i className="bi bi bi-info-circle me-2"></i>Support
            </button>
          </div> */}
          <div className="w-100 mb-2">
            <button
              className={
                Boolean("/support-chat" == location?.pathname)
                  ? "side-button active"
                  : "side-button"
              }
              onClick={() => supportClicked()}
            >
              <i className="bi bi bi-info-circle me-2"></i>Support
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
