import React, { useEffect, useRef, useState } from "react";
import AppLayout from "../../../Component/AppLayout/AppLayout";
import { useNavigate, useParams } from "react-router-dom";
import { fetchDealerDetails, updateDealerDetails } from "../../../Api";
import { useFormik } from "formik";
import { BlueSpinner } from "../../../Utils/Loader";
import AutoFillAddress from "../../../Component/AutoFillAddress/AutoFillAddress";
import {
  assignBucketUrl,
  globalHandleChange,
  handleFileUpload,
} from "../../../Utils/HelperFunctions";
import { updateDealerValidation } from "../../../Utils/Validations";
import ProfilePicDemo from "../../../Assets/image/person.png";
import { toast } from "react-hot-toast";
import { API_BASE_URL } from "../../../Utils/BaseUrl";
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css'


const EditDealerProfile = () => {
  const [dealerData, setDealerData] = useState({ data: null, loading: false });
  const [loader, setLoader] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const imgRef = useRef();
  const inputRef = useRef();

  const formikDealer = useFormik({
    initialValues: {
      id: "",
      firstName: "",
      lastName: "",
      phone: "",
      address: "",
      company: "",
      zipCode: "",
      city: "",
      state: "",
      profilePicture: "",
    },

    validationSchema: updateDealerValidation,

    onSubmit: async () => {
      const values = formikDealer?.values;
      // console.log("Dealer hadle submit...", values);
      try {
        const res = await updateDealerDetails(values);
        if (res?.status === 200) {
          toast.success("Dealer profile updated successfully", { id: "001" });
          fetchDealerDetailsById(params?.id);
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const fetchDealerDetailsById = async (id) => {
    setDealerData({ ...dealerData, loading: true });
    try {
      const res = await fetchDealerDetails(id);
      if (res?.status === 200) {
        setDealerData({ data: res?.data, loading: false });
      }
    } catch (error) {
      setDealerData({ ...dealerData, loading: false });
      console.log(error);
    }
  };

  const handleChangePhoneInput = (value) => {
    formikDealer.setFieldValue("phone", value);
  };

  const handleRemove = () => {
    inputRef.current.src = ProfilePicDemo;
    formikDealer.setFieldValue("profilePicture", "")
  };

  useEffect(() => {
    fetchDealerDetailsById(params?.id);
  }, []);

  useEffect(() => {
    if (dealerData?.data !== "") {
      formikDealer.setFieldValue(
        "id",
        dealerData?.data?.id.toString() || "-"
      );
      formikDealer.setFieldValue(
        "firstName",
        dealerData?.data?.firstName || "-"
      );
      formikDealer.setFieldValue(
        "lastName",
        dealerData?.data?.lastName || "-"
      );
      formikDealer.setFieldValue("email", dealerData?.data?.email || "-");
      formikDealer.setFieldValue("phone", dealerData?.data?.phone || "-");
      formikDealer.setFieldValue("company", dealerData?.data?.company || "-");
      formikDealer.setFieldValue("address", dealerData?.data?.address || "-");
      formikDealer.setFieldValue("city", dealerData?.data?.city || "-");
      formikDealer.setFieldValue("state", dealerData?.data?.state || "-");
      formikDealer.setFieldValue("zipCode", dealerData?.data?.zipCode || "-");
      formikDealer.setFieldValue("profilePicture", dealerData?.data?.profilePicture || "");

    }
  }, [dealerData?.data]);

  console.log(dealerData?.data, "dealerData");

  return (
    <>
      <AppLayout>
        <main className="p-3 px-5 main">
          <div className="row d-flex">
            <div className="col-lg-6 col-12 d-flex align-items-center ">
              <div className="col-9">
                <h3 className="fw-bold py-4">Edit information</h3>
              </div>
              <div className="col-3 py-4">
                <i
                  className="bi bi-arrow-left-circle-fill h3 m-0"
                  onClick={() => navigate("/user-management")}
                  style={{ cursor: "pointer", float: "right" }}
                ></i>
              </div>
            </div>
          </div>
          {/* <div className="content mt-3  "> */}

          <div className="row ">
            {dealerData?.loading ? (
              <div className="text-center">
                <BlueSpinner />
              </div>
            ) : (
              <div className="col-lg-6 col-12">
                <div>
                  <div className="d-flex mb-3 justify-content-between align-items-center">
                    {/* <div className="profilePic" >
                                                    <img src="./assets/image/x-whitebg.svg" alt="" className="XIcon" />
                                                    <img src="https://mdbootstrap.com/img/Photos/Avatars/img%20(31).jpg" alt="" className="EditProfilePic" style={{ height: "100%", width: "100%", objectFit: "cover", borderRadius: "5px" }} />
                                                </div>
                                                <div className="ms-4">
                                                    <button className=" px-4 py-1 button button-bg button-color fw-semibold">
                                                        Upload
                                                    </button>
                                                </div> */}
                    <div
                      style={{
                        height: "50px",
                        width: "50px",
                        position: "relative",
                      }}
                      className=" "
                    >
                      <span className="image-section position-absolute end-0">
                        <i
                          className="bi bi-x-circle"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleRemove()}
                        ></i>
                      </span>
                      <img
                        ref={inputRef}
                        src={assignBucketUrl(formikDealer?.values?.profilePicture)}
                        alt="#"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        className="border rounded-3 "
                        onError={(event) => {
                          event.target.src = ProfilePicDemo;
                          event.onerror = null;
                        }}
                      />
                    </div>
                    <label htmlFor="profilePic">
                      <div
                        onClick={() => {
                          imgRef?.current?.click();
                          // formikDealer.setFieldValue("profilePicture", "");
                        }}
                        style={{ cursor: "pointer" }}
                        className=" px-4 py-1 button button-bg button-color fw-semibold"
                      >
                        Upload
                      </div>
                    </label>
                    <input
                      type="file"
                      id="profilePic"
                      name="profilePicture"
                      onChange={(e) => {
                        handleFileUpload(e, formikDealer, setLoader);
                        // formikDealer.setFieldValue("profilePicture", "");
                      }}
                      className="d-none"
                      accept="image/*"
                    />
                  </div>

                  <div className="mb-3">
                    {/* <label
                      htmlFor="firstName"
                      className="form-label fw-semibold p-14 "
                    >
                      First Name
                    </label> */}
                    <input
                      type="text"
                      className="form-control "
                      id="firstName "
                      placeholder="Enter your first name"
                      name="firstName"
                      value={formikDealer?.values?.firstName}
                      onChange={(e) => globalHandleChange(e, formikDealer)}
                    />
                    {Boolean(
                      formikDealer.touched.firstName &&
                      formikDealer.errors.firstName
                    ) && (
                        <p className="text-danger mt-2">
                          {formikDealer.errors.firstName}
                        </p>
                      )}
                  </div>
                  <div className="mb-3">
                    {/* <label
                      htmlFor="lastName"
                      className="form-label fw-semibold p-14 "
                    >
                      Last Name
                    </label> */}
                    <input
                      type="text"
                      className="form-control "
                      id="lastName "
                      placeholder="Enter your last name"
                      name="lastName"
                      value={formikDealer?.values?.lastName}
                      onChange={(e) => globalHandleChange(e, formikDealer)}
                    />
                    {Boolean(
                      formikDealer.touched.lastName &&
                      formikDealer.errors.lastName
                    ) && (
                        <p className="text-danger mt-2">
                          {formikDealer.errors.lastName}
                        </p>
                      )}
                  </div>

                  <div className="mb-3">
                    {/* <label
                      htmlFor="InputEmail1"
                      className="form-label  fw-semibold"
                    >
                      Email
                    </label> */}
                    <div className="position-relative">
                      <input
                        type="email"
                        className="form-control"
                        id="InputEmail1"
                        placeholder="Enter your email "
                        name="email"
                        value={formikDealer?.values?.email}
                        onChange={(e) => globalHandleChange(e, formikDealer)}
                      />
                      <i className="bi bi-envelope-fill position-absolute icon-p  text-fade"
                        style={{
                          cursor: 'pointer', right: "1rem", top: "1rem",
                          background: "transparent !important", marginLeft: "10px"
                        }}></i>
                      {Boolean(
                        formikDealer.touched.email && formikDealer.errors.email
                      ) && (
                          <p className="text-danger mt-2">
                            {formikDealer.errors.email}
                          </p>
                        )}
                    </div>
                  </div>

                  {/* <div className="mb-3">
                    <label
                      htmlFor="phoneNumber"
                      className="form-label fw-semibold p-14 "
                    >
                      Phone Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="phoneNumber"
                      placeholder="Enter your phone number"
                      name="phone"
                      value={formikDealer?.values?.phone}
                      onChange={(e) => globalHandleChange(e, formikDealer)}
                    />
                  </div> */}

                  <div className="mb-3">
                    {/* <label
                      htmlFor="phoneNumber"
                      className="form-label fw-semibold p-14 "
                    >
                      Phone Number
                    </label> */}
                    <PhoneInput
                      international={false}
                      className={
                        Boolean(
                          formikDealer.touched?.phone &&
                          formikDealer.errors?.phone
                        )
                          ? "border-danger form-control p-3 cu-45 e-caret-hide"
                          : "cu-45  form-control p-3 e-caret-hide"
                      }

                      defaultCountry="US"
                      countries={["US"]}
                      name="phone"
                      placeholder="Enter phone number"
                      value={formikDealer.values.phone}
                      onChange={handleChangePhoneInput}
                      PhoneInputCountrySelectArrow={false}

                    />

                    {Boolean(
                      formikDealer.touched.phone && formikDealer.errors.phone
                    ) && (
                        <p className="text-danger mt-2">
                          {formikDealer.errors.phone}
                        </p>
                      )}
                  </div>

                  <div className="mb-3">
                    {/* <label for="type" className="form-label fw-semibold p-14 ">
                      Type
                    </label> */}
                    {/* <select
                      className="form-select "
                      aria-label="Default select example"
                    >
                      <option selected>Select type</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select> */}
                  </div>
                  {
                    console.log(dealerData?.data)
                  }

                  {
                    dealerData?.data?.isBusiness === false
                      ?
                      ("")
                      :
                      (<>
                        <div className="mb-3">
                          {/* <label
                      htmlFor="InputEmail1"
                      className="form-label  fw-semibold"
                    >
                      Comapny
                    </label> */}

                          <input
                            type="email"
                            className="form-control"
                            id="company"
                            placeholder="Enter your company...bvsdfhbxdg"
                            name="company"
                            value={formikDealer?.values?.company}
                            onChange={(e) => globalHandleChange(e, formikDealer)}
                          />

                          {Boolean(
                            formikDealer.touched.company &&
                            formikDealer.errors.company
                          ) && (
                              <p className="text-danger mt-2">
                                {formikDealer.errors.company}
                              </p>
                            )}

                        </div>
                      </>)
                  }

                  <AutoFillAddress formik={formikDealer} />

                  {/* <div className="mb-3">
                                                <label htmlFor="address" className="form-label fw-semibold p-14 ">Address</label>
                                                <input type="text" className="form-control" id="address"
                                                    placeholder="flat number/house number" />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="zipCode" className="form-label fw-semibold p-14 ">Zip code</label>
                                                <input type="text" className="form-control" id="zipCode"
                                                    placeholder="Enter your zip code" />
                                            </div>
                                            <div className="mb-3 row">
                                                <div className="col-md-6">
                                                    <label htmlFor="type" className="form-label fw-semibold p-14 ">City</label>
                                                    <select className="form-select" aria-label="Default select example">
                                                        <option selected>Enter your city</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </select>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="type" className="form-label fw-semibold p-14 ">State</label>
                                                    <select className="form-select" aria-label="Default select example">
                                                        <option selected>Enter your city</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </select>
                                                </div>

                                                <div className=" mt-5">
                                                    <button className=" px-5 button button-bg button-color fw-semibold">
                                                        Save
                                                    </button>
                                                </div>

                                            </div> */}
                </div>
                <div className="col-lg-4 col-12">
                  <div className=" mt-5">
                    <button
                      className=" px-5 button button-bg button-color fw-semibold"
                      onClick={() => {
                        formikDealer.handleSubmit();
                      }}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </main>
      </AppLayout>
    </>
  );
};

export default EditDealerProfile;
